<template>
  <div>
    <div class="justify-center align-center">
      <v-img
        :src="require(`../../assets/img/${selectedPlan.path}`)"
        width="100%"
        height="100vh"
      >
        <!-- <div class="middleText textJustify"> -->
        <v-row class="justify-center align-center">
          <v-card
            :width="$vuetify.breakpoint.mdAndUp ? '60rem' : '100%'"
            color="#000000"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'margin-top: 10%; padding-bottom: 3%'
                : 'margin-top: 30%; padding-bottom: 3%; border-radius:30px'
            "
            :class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : 'rounded-t-xl'"
          >
            <!-- fileds -->
            <v-card-text v-show="mapSelect">
              <v-card-title class="justify-center align-center">
                <span
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'text-h3'
                      : 'text-h5 font-weight-bold'
                  "
                >
                  {{ selectedPlan.title + selectedPlan.short }}
                </span>
              </v-card-title>
              <!-- form -->
              <v-form
                class="justify-center mt-5"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-container fluid>
                  <v-row align="center" justify="space-around" dense>
                    <v-col align="left" cols="12" md="5">
                      <p>Latitude</p>
                      <v-text-field
                        color="#FFC700"
                        v-model="lat"
                        type="number"
                        hide-spin-buttons
                        :rules="[rules.required]"
                        solo
                        background-color="#191919"
                        style="border-radius: 15px"
                        prepend-inner-icon="mdi-map-marker"
                        label="Latitude"
                      ></v-text-field>
                    </v-col>

                    <v-col align="left" cols="12" md="5">
                      <p>Longitude</p>
                      <v-text-field
                        hide-spin-buttons
                        color="#FFC700"
                        v-model="lon"
                        type="number"
                        :rules="[rules.required]"
                        solo
                        background-color="#191919"
                        style="border-radius: 15px"
                        prepend-inner-icon="mdi-map-marker"
                        label="Longitude"
                      ></v-text-field>

                      <v-btn
                        small
                        absolute
                        right
                        @click="mapSelect = !mapSelect"
                        color="transparent"
                        style="
                          opacity: 1;
                          background: linear-gradient(
                            180deg,
                            #000e1e 1.05%,
                            #013066 99.01%
                          );
                          border-radius: 10px;

                          margin-top: -1rem;
                          font-family: 'Poppins', sans-serif;
                        "
                        :style="
                          $vuetify.breakpoint.mdAndUp
                            ? 'margin-right: 3rem;'
                            : 'margin-right: 1rem;'
                        "
                      >
                        <v-icon class="mr-2">mdi-map</v-icon>
                        choose on map
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-around" dense>
                    <v-col align="left" cols="11" md="11">
                      <div class="mb-4">
                        <div class="switches-container">
                          <input
                            type="radio"
                            id="switchMonthly"
                            name="switchPlan"
                            value="Area"
                            @click="model = true"
                            checked
                          />
                          <input
                            type="radio"
                            id="switchYearly"
                            name="switchPlan"
                            value="Capacity"
                            @click="model = false"
                          />
                          <label for="switchMonthly">Area</label>
                          <label for="switchYearly">Capacity</label>
                          <div class="switch-wrapper">
                            <div class="switch">
                              <div>Area</div>
                              <div>Capacity</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <v-row align="center" justify="space-around" dense>
                        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 9 : 7">
                          <v-text-field
                            color="#FFC700"
                            v-if="model"
                            hide-spin-buttons
                            :rules="model ? [rules.required] : []"
                            solo
                            :height="
                              $vuetify.breakpoint.mdAndUp ? '3.5rem' : '2rem'
                            "
                            type="number"
                            v-model="area"
                            background-color="#191919"
                            style="border-radius: 15px"
                            prepend-inner-icon="mdi-map"
                            placeholder="Area(m,Km,hec)"
                            label="Area(m,Km,hec)"
                          ></v-text-field>

                          <v-text-field
                            v-else
                            color="#FFC700"
                            hide-spin-buttons
                            v-model="cap"
                            :rules="model ? [] : [rules.required]"
                            :height="
                              $vuetify.breakpoint.mdAndUp ? '3.5rem' : '2rem'
                            "
                            type="number"
                            solo
                            background-color="#191919"
                            style="border-radius: 15px"
                            prepend-inner-icon="mdi-view-module"
                            label="Capacity(KW,MW)"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-select
                            color="white"
                            :rules="model ? [rules.required] : []"
                            :height="
                              $vuetify.breakpoint.mdAndUp ? '3.5rem' : '1.5rem'
                            "
                            v-if="model"
                            v-model="AreaUnit"
                            outlined
                            style="border-radius: 15px"
                            placeholder="unit"
                            :items="
                              model ? unitList.areaList : unitList.capacityList
                            "
                          ></v-select>
                          <v-select
                            color="white"
                            v-else
                            :rules="model ? [] : [rules.required]"
                            :height="
                              $vuetify.breakpoint.mdAndUp ? '3.5rem' : '1.5rem'
                            "
                            v-model="CapUnit"
                            outlined
                            style="border-radius: 15px"
                            placeholder="unit"
                            :items="
                              model ? unitList.areaList : unitList.capacityList
                            "
                          ></v-select>
                        </v-col>
                        <v-col cols="12" align="left">
                          <span style="opacity: 0.5; text-align: left">
                            <v-icon small>mdi-information-outline</v-icon>
                            Based on the information available to you, you can
                            input either the intended capacity or the amount of
                            available land at this stage.
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <!-- map -->
            <v-card-text v-show="!mapSelect">
              <v-container>
                <h3 class="text-left">
                  Determine the coordinates of your land on map
                </h3>
              </v-container>

              <v-container>
                <div>
                  <gmap-map
                    :options="mapOptions"
                    :zoom="14"
                    :center="center"
                    style="width: 100%; height: 350px"
                    @click="onMapClick"
                  >
                    <gmap-marker :position="myLoc"></gmap-marker>
                  </gmap-map>
                </div>
              </v-container>
            </v-card-text>
            <!-- actions -->
            <v-card-actions :class="$vuetify.breakpoint.mdAndUp ? '' : 'mb-5'">
              <v-row align="center" justify="center" v-show="mapSelect" dense>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :disabled="!valid"
                    :style="
                      hover
                        ? 'border-radius: 10px; color: black;'
                        : ' border-radius: 10px;'
                    "
                    color="#FFC700"
                    width="85%"
                    :outlined="hover ? false : true"
                    @click="createOrder()"
                  >
                    save & apply</v-btn
                  >
                </v-hover>
              </v-row>

              <v-row align="center" justify="center" v-show="!mapSelect" dense>
                <v-btn
                  outlined
                  style="border-radius: 10px"
                  color="#0075FF"
                  class="mr-2"
                  @click="
                    [(mapSelect = !mapSelect), (lat = null), (lon = null)]
                  "
                >
                  Cancel</v-btn
                >

                <v-btn
                  outlined
                  style="border-radius: 10px"
                  color="#FFC700"
                  @click="mapSelect = !mapSelect"
                >
                  save</v-btn
                >
              </v-row>
            </v-card-actions>
          </v-card>

          <!-- result order dialog -->
          <!-- loading -->

          <v-dialog persistent max-width="90%" v-model="resOrder">
            <v-overlay :value="overlay">
              <v-progress-circular
                v-show="overlay"
                indeterminate
                size="84"
              ></v-progress-circular>
            </v-overlay>

            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  icon
                  dark
                  @click="[(resOrder = false), (overlay = false)]"
                >
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text v-if="overlay">
                <v-skeleton-loader
                  v-bind="attrs"
                  type="article, actions"
                ></v-skeleton-loader>

                <v-skeleton-loader
                  v-bind="attrs"
                  type="table-heading, list-item-two-line, image, table-tfoot"
                ></v-skeleton-loader>
              </v-card-text>
              <v-card-text v-else>
                <details-table :detailData="resOrderData"></details-table>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>

        <!-- </div> -->
      </v-img>
    </div>
  </div>
</template>

<script>
import DetailsTable from "./DetailsTable.vue";

export default {
  components: {
    DetailsTable,
  },

  data() {
    return {
      mapOptions: {
        styles: require("@/assets/css/map-style.json"),
      },
      myLoc: {
        lat: 0,
        lng: 0,
      },

      center: {
        lat: 35.726330654630296,
        lng: 51.37974546401698,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,

      attrs: {
        boilerplate: false,
        elevation: 2,
      },
      resOrder: false,
      valid: true,
      rules: {
        required: (value) => !!value || "Required",
      },
      model: true,
      mapSelect: true,
      lat: null,
      lon: null,
      area: null,
      cap: null,
      CapUnit: null,
      AreaUnit: null,
      unitList: {
        areaList: ["m2", "hectares", "km2" ,"acre"],
        capacityList: ["kW", "MW"],
      },
    };
  },
  computed: {
    selectedPlan() {
      return this.$route.params.item || {};
    },
    resOrderData() {
      return this.$store.state.orders.resultOrder || {};
    },
    overlay() {
      return Object.keys(this.resOrderData).length === 0 || false;
    },
  },

  methods: {
    createOrder() {
      if (localStorage.getItem("token")) {
        this.$store.commit("setResultOrder", {});

        if (this.$refs.form.validate()) {
          this.resOrder = true;
          this.overlay = true;
          if (this.model) {
            const f = {
              panelType: this.selectedPlan.value,
              lat: this.lat,
              lon: this.lon,
              area: this.area,
              areaUnit: this.AreaUnit,
            };

            this.$store
              .dispatch("CreateAnOrder", f)
              .then(() => {
                this.overlay = true;
                this.resOrder = true;
                this.$refs.form.reset();
              })
              
          } else {
            const f = {
              panelType: this.selectedPlan.value,
              lat: this.lat,
              lon: this.lon,
              capacity: this.cap,
              capacityUnit: this.CapUnit,
            };

            this.$store
              .dispatch("CreateAnOrder", f)
              .then(() => {
                this.overlay = true;
                this.resOrder = true;
                this.$refs.form.reset();
              })
              // .catch((e) => {
              //   console.log(e.message );
              //   this.overlay = false;
              // });
          }
          this.overlay = Object.keys(this.resOrderData).length === 0 || false;
        }
      } else {
        this.$store.commit("showMessage", {
          content: "Please Login For Creating an Order !",
          color: "error",
        });
        this.$router.push({
          name: "Login",
          params: {
            text: "Login",
          },
        });
      }
    },
    // map stuff
    onMapClick(event) {
      console.log(event.latLng.lat());
      console.log(event.latLng.lng());
      this.myLoc = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.lat = event.latLng.lat();
      this.lon = event.latLng.lng();
    },
  },
  created() {
    if (!this.$route.params.item) this.$router.push("/");
  },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
}
.textJustify {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  opacity: 1 !important;
}

.middleText {
  position: absolute;
  left: 15.71%;
  right: 15.86%;
  top: 13.09%;
  bottom: 56.52%;
}

.fill-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.switches-container {
  width: 10rem;
  position: relative;
  display: flex;
  padding: 0;
  background: black;
  line-height: 2.5rem;
  border-radius: 2.5rem;
  border: 1px solid #ffc700;
}

.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #ffc700;
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.switch {
  border-radius: 2.5rem;
  background: #ffc700;
  height: 100%;
}

.switch div {
  color: black;
  width: 100%;
  text-align: center;
  opacity: 0;
  font-weight: normal;
  display: block;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}
</style>
