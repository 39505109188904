<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" width="85vw" persistent>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="#FFC700"
          style="border-radius: 6px"
          outlined
          :small="$vuetify.breakpoint.mobile"
        >
          <v-icon> mdi-plus</v-icon>
         <span  style="color: #FFC700;"> new request</span>
        </v-btn>
      </template>
      <template #default="dialog">
        <v-card rounded="xl">
          <v-toolbar dark color="#FFC700">
            <span  style="color: black">
              Choose Your Solar System Type :
            </span>
            <v-spacer></v-spacer>
            <v-btn fab icon color="black" @click="dialog.value = false">
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>

          <v-card-text>
            <plans class="mt-5 mb-5" />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import Plans from "../Plans.vue";
export default {
  components: { Plans },
};
</script>

<style></style>
