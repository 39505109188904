<template>
  <div>
    <home />
    <Plans class="mt-8" />
    <About v-if="$vuetify.breakpoint.mdAndUp" class="mt-8" />
    <Footer class="mt-8" />
    <!-- ================ whatsapp ======================= -->
    <v-btn
      @click="connectToWhatsapp"
      small
      fab
     
      color="success"
      :style="`position: fixed; bottom: 1rem;${$vuetify.breakpoint.mdAndUp ? 'left: 93%;':'left: 80%;'}  padding: 1.7rem;`"
    >
      <v-icon size="35" class="align-center justify-center">mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Plans from "@/components/Plans.vue";
import Home from "@/components/Home.vue";
import Footer from "@/components/partial/Footer.vue";
import About from "@/components/About.vue";

export default {
  name: "HomeView",
  components: {
    Plans,
    Home,
    Footer,
    About,
  },
  methods: {
    scrollToThere() {
      var myElement = document.getElementById("element_within_div");
      var topPos = myElement.offsetTop;
      document.getElementById("scrolling_div").scrollTop = topPos;
    },

    connectToWhatsapp() {
      const phoneNumber = "989365710029"; // Replace with your phone number
      window.open("https://wa.me/" + phoneNumber, "_blank");
    },
  },
};
</script>
