<template>
  <div>
    <v-container fluid>
      <v-img
        :src="
          $vuetify.breakpoint.mdAndUp
            ? ''
            : require('/src/assets/img/login-mobile.png')
        "
      >
        <v-row>
          <v-card
            :color="$vuetify.breakpoint.mdAndUp ? 'black' : 'transparent'"
            width="100%"
            flat
          >
            <v-card-text>
              <v-row>
                <v-col
                  :align="$vuetify.breakpoint.mdAndUp ? 'left' : 'center'"
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  :class="$vuetify.breakpoint.mdAndUp ? 'pa-5' : ''"
                >
                  <v-container
                    :fluid="$vuetify.breakpoint.mdAndUp ? false : true"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ma-5' : ''"
                  >
                    <v-card
                      flat
                      :color="
                        $vuetify.breakpoint.mdAndUp ? 'black' : 'transparent'
                      "
                    >
                      <v-card-title class="justify-center">
                        <v-img
                          v-if="$vuetify.breakpoint.mdAndUp"
                          src="../assets/img/KSI-original.png"
                          max-width="150"
                        ></v-img>
                        <v-img
                          v-else
                          src="../assets/img/ksg_white.png"
                          max-width="120"
                        ></v-img>
                      </v-card-title>
                      <br />
                      <v-card-text>
                        <h2
                          :class="
                            $vuetify.breakpoint.mdAndUp
                              ? 'text-h2 font-weight-bold text--white'
                              : 'text-h4 font-weight-bold text--white'
                          "
                        >
                          Welcome
                          <span
                            :class="
                              $vuetify.breakpoint.mdAndUp
                                ? ''
                                : 'text-h4 font-weight-bold'
                            "
                            v-if="$route.params.text === 'Login'"
                            >Back</span
                          >
                        </h2>
                        <br />
                        <h4 v-if="$route.params.text === 'Login'">
                          Login to your account
                        </h4>
                        <h4 v-else>Create your account</h4>
                      </v-card-text>

                      <v-card-text>
                        <v-form
                          @submit.prevent="letsGo"
                          ref="form"
                          v-model="valid"
                          lazy-validation
                        >
                          <!-- <p>Email</p> -->
                          <v-text-field
                            color="#FFC700"
                            :disabled="forgetPass || showOtp"
                            style="border-radius: 15px"
                            :outlined="$vuetify.breakpoint.mdAndDown"
                            prepend-inner-icon="mdi-email"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            solo
                            :background-color="
                              $vuetify.breakpoint.mdAndUp
                                ? '#191919'
                                : '#000000'
                            "
                            label="Email"
                            required
                          ></v-text-field>

                          <!-- name -->
                          <v-text-field
                            color="#FFC700"
                            :outlined="$vuetify.breakpoint.mdAndDown"
                            :disabled="showOtp"
                            v-if="$route.params.text === 'sign up'"
                            style="border-radius: 15px"
                            prepend-inner-icon="mdi-account"
                            v-model="username"
                            :rules="[rules.required]"
                            solo
                            :background-color="
                              $vuetify.breakpoint.mdAndUp
                                ? '#191919'
                                : '#000000'
                            "
                            label="Full Name"
                            required
                          ></v-text-field>

                          <!-- <p>Password</p> -->
                          <v-text-field
                            color="#FFC700"
                            :disabled="showOtp"
                            prepend-inner-icon="mdi-lock"
                            :outlined="$vuetify.breakpoint.mdAndDown"
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="
                              $route.params.text === 'Login'
                                ? [rules.required]
                                : [rules.required, rules.min]
                            "
                            :type="show1 ? 'text' : 'password'"
                            label="Password"
                            solo
                            :background-color="
                              $vuetify.breakpoint.mdAndUp
                                ? '#191919'
                                : '#000000'
                            "
                            style="border-radius: 15px"
                            :hint="
                              $route.params.text === 'Login'
                                ? ''
                                : 'At least 8 characters'
                            "
                            :counter="
                              $route.params.text === 'Login' ? false : true
                            "
                            @click:append="show1 = !show1"
                            required
                          ></v-text-field>

                          <v-row align="center" class="ml-2 mt-3" dense>
                            <v-spacer></v-spacer>

                            <v-btn
                              :disabled="showOtp"
                              x-small
                              :color="
                                $vuetify.breakpoint.mdAndUp
                                  ? 'primary'
                                  : 'white'
                              "
                              v-if="$route.params.text === 'Login'"
                              @click="forgetPassFunc()"
                              text
                              >Forget Password ?</v-btn
                            >
                          </v-row>
                        </v-form>
                      </v-card-text>

                      <!--    OTP    -->
                      <v-card-text>
                        <v-row>
                          <div
                            v-show="showOtp"
                            class="ma-auto position-relative"
                            style="max-width: 400px"
                          >
                            <!--  time count -->

                            <div>
                              <flip-countdown
                                v-if="showOtp"
                                @timeElapsed="timeElapsedHandler"
                                :deadline="countDown.toLocaleString()"
                                :showDays="false"
                                :showHours="false"
                              ></flip-countdown>
                            </div>
                            <v-otp-input
                              v-model="otp"
                              :disabled="loading"
                              type="number"
                              @finish="onFinish"
                            ></v-otp-input>
                            <span class="mt-5">
                              Enter verfication number that has sent to your
                              E-mail
                            </span>
                            <v-card-actions>
                              <v-btn
                                outlined
                                color="error"
                                :disabled="otp === ''"
                                @click="[(otp = ''), (loading = false)]"
                                >Empty Otp</v-btn
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                color="success"
                                :disabled="resendCode"
                                @click="
                                  forgetPass
                                    ? forgetPassFunc()
                                    : [(otp = ''), (loading = false), getOtp()]
                                "
                                >resend code</v-btn
                              >
                            </v-card-actions>
                          </div>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="mt-5">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            v-show="!showOtp"
                            :disabled="!valid"
                            @click="letsGo()"
                            :outlined="
                              $vuetify.breakpoint.mdAndUp
                                ? hover
                                  ? false
                                  : true
                                : false
                            "
                            :style="
                              hover
                                ? 'border-radius: 10px; color: black;'
                                : 'border-radius: 10px;'
                            "
                            :class="
                              $vuetify.breakpoint.mdAndUp ? '' : 'black--text'
                            "
                            color="#FFC700"
                            :loading="btnLoading"
                            type="submit"
                            block
                          >
                            {{ $route.params.text }}</v-btn
                          >
                        </v-hover>
                      </v-card-actions>
                      <v-card-actions class="mt-5">
                        <v-row align="center">
                          <v-divider class="mr-2"></v-divider>
                          Or
                          <v-divider class="ml-2"></v-divider>
                        </v-row>
                      </v-card-actions>
                      <v-card-actions class="text-center justify-center">
                        <h4 v-if="$route.params.text === 'sign up'">
                          Already have an account ?
                          <v-btn
                            x-small
                            text
                            color="#FFC700"
                            @click="goToSignUPOrLogin('Login')"
                          >
                            Login</v-btn
                          >
                        </h4>

                        <h4 v-else>
                          Dont have an account ?
                          <v-btn
                            x-small
                            text
                            color="#FFC700"
                            @click="goToSignUPOrLogin('sign up')"
                          >
                            sign up</v-btn
                          >
                        </h4>
                      </v-card-actions>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="6" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-img
                    height="100%"
                    class="r-5"
                    src="../assets/img/login_photo.png"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-img>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import FlipCountdown from "vue2-flip-countdown";

export default {
  components: { FlipCountdown },
  name: "LoginPage",

  data: () => ({
    btnLoading: false,
    valid: true,
    countDown: "",
    otp: "",
    loading: false,
    forgetPass: false,
    checkbox: false,
    email: "",
    show1: false,
    show2: false,
    password: "",
    username: "",
    rules: {
      required: (value) => !!value || "Required",
      min: (v) => v.length >= 6 || "Min 6 characters",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    isMobile: false,
    resendCode: true,
    mobile: "",
    showOtp: false,
  }),

  created() {
    if (!this.$route.params.text) {
      this.$router.push("/");
    }
  },

  methods: {
    goToSignUPOrLogin(d) {
      this.$router.push("/").then(async () => {
        await this.$router.replace({
          name: "Login",
          params: {
            text: d,
          },
        });
      });
    },

    changepass(d) {
      const Url = "http://65.108.91.247/api";
      axios({
        method: "post",
        url: Url + "/auth/forget-password",
        headers: {},
        data: d,
      })
        .then(async (res) => {
         

          if (Object.keys(d).includes("otp") && res.data === true) {
            // this.forgetPass = false;
            this.showOtp = false;
            this.$store.commit("showMessage", {
              content: "Otp is Correct ! Now change Your Password ",
              color: "success",
            });
          }
          
          if (res.data === 'password successfully updated') {
            this.$store.commit("showMessage", {
              content: "password is successfuly changed , welcome",
              color: "primary",
            });
            await this.letsLogin();
          } 
        })
        .catch((e) => {
          const k = JSON.parse(e.request.response);
          this.$store.commit("showMessage", {
            content: k.message,
            color: "error",
          });
        });
    },

    forgetPassFunc() {
      if (this.email.length > 0) {
        this.showOtp = true;
        this.countDown = new Date();
        this.addMinutes(this.countDown, 2);
        this.changepass({ email: this.email });
        this.forgetPass = true;
      }
    },

    checkOtp() {
      this.loading = true;
      this.changepass({ email: this.email, otp: this.otp });
    },

    passchanged() {
      this.changepass({ email: this.email, otp: this.otp , newPassword: this.password });
    },

    rememberMe() {
      if (localStorage.getItem("token")) {
        this.$store.dispatch("fetchProfile").then((res) => {
          console.log(res);
        });
      }
    },

    letsGo() {
      this.btnLoading = true;

      if (this.$route.params.text === "Login") {
        if (this.forgetPass) {
          this.passchanged();
        } else {
          this.letsLogin();
        }
      } else {
        if (this.$refs.form.validate()) {
          this.showOtp = true;
          this.countDown = new Date();
          this.addMinutes(this.countDown, 2);
          this.getOtp();
        }
      }
      this.btnLoading = false;
    },

    addMinutes(date, minutes) {
      date.setMinutes(date.getMinutes() + minutes);

      return date;
    },

    getOtp() {
      const Url = "http://65.108.91.247/api";
      axios({
        method: "post",
        url: Url + "/auth/otp",
        headers: {},
        data: {
          email: this.email,
        },
      })
        .then((res) => {
          if (res.data.code < 220) {
            console.log(res.data);
            this.$store.commit("showMessage", {
              content: res.data.message,
              color: "success",
            });
            this.resendCode = true;
          } else {
            this.$store.commit("showMessage", {
              content: res.data.message,
              color: "warning",
            });
          }
        })
        .catch((e) => {
          const k = JSON.parse(e.request.response);
          // console.log(k.message)
          this.$store.commit("showMessage", {
            content: k.message,
            color: "error",
          });
        });
    },

    timeElapsedHandler() {
      this.loading = false;
      this.resendCode = false;
      this.otp = "";
    },

    onFinish() {
      if (this.$route.params.text === "sign up") {
        this.loading = true;
        this.resendCode = true;
        this.letsSignUp();
      } else {
        this.checkOtp();
      }
    },

    async letsLogin() {
      
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            // console.log(res)
            //   this.$store.commit("showMessage", {
            //   content: 'Welcome !',
            //   color: "success",
            // });
            // this.$router.back();
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    },

    async letsSignUp() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("signUp", {
            email: this.email,
            fullName: this.username,
            password: this.password,
            otp: this.otp,
          })
          .then((response) => {
            if (response.data.success) {
              console.log(response.data.success);
            } else {
              this.$store.commit("showMessage", {
                content: response.message,
                color: "error",
              });
            }
          })
          .catch((e) => {
            console.log(e.response.data.message);
          });
      }
    },
  },
};
</script>

<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: transparent;
  color: #fff;
}

.position-relative {
  position: relative;
}
</style>
