<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-container fluid class="px-10" style="height: 100vh; margin-top: 7rem">
        <v-row class="justify-center align-center">
          <v-col align="left">
            <span
              class="taghireFont white--text font-weight-bold text-h3"
            >
              Solution to
              <span class="my_custom_orange text-h3 font-weight-bold"
                >save energy</span
              >
              and
              <!-- <br v-if="$vuetify.breakpoint.mdAndUp" /> -->
              <span class="text-h3 protect_color"> protect</span>
              the Environment
            </span>

            <br />
            <br />
            <br />
            <br />
            <br />

            <span class="text-subtitle white--text ">Input your solar dreams, we'll help turn them into reality.
            </span>

            <br />
            <br />

            <v-btn color="#ffc700" class="rounded-lg" @click="scrollGain()">
              <span class="black--text font-weight-bold"
                >View Solar System Types</span
              >
            </v-btn>
          </v-col>
          <v-col cols="7">
            <v-img
              style="border: 2px solid"
              class="rounded-xl"
              src="../assets/img/ksi_bg.png"
              width="100vw"
              height="70vh"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <div v-if="isVisible" class="textJustify downText">
            <v-btn
              id="btn2"
              small
              color="white"
              fab
              style="opacity: 0.7"
              @click="scrollGain()"
            >
              <v-icon size="35" color="black">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-container>

  
    </div>

    <!-- =========================   MOBILE   ======================== -->

    <div v-else>
      <v-img
        style="position: relative; "
        src="../assets/img/mobile-bg.png"
        width="100%"
        height="100vh"
      ></v-img>
      <div class="textJustify_mobile middleText_mobile">
        <span class="taghireFont white--text font-weight-bold text-h4">
          Solution to
          <span class="my_custom_orange text-h4 font-weight-bold"
            > save energy </span
          >and
          <span class="text-h4 protect_color"> protect</span>
          the Environment
        </span>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <span
          >Input your solar dreams, we'll help turn them into reality.
        </span>

        <br />
        <br />

        <v-btn color="#ffc700" class="rounded-lg" @click="scrollGain()">
          <span class="black--text font-weight-bold"
            >View Solar System Types</span
          >
        </v-btn>
      </div>
      <div v-if="isVisible" class="textJustify downText">
        <v-btn
          id="btn2"
          small
          color="white"
          fab
          style="opacity: 0.7"
          @click="scrollGain()"
        >
          <v-icon size="35" color="black">mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </div>


    <!-- ===================how it works=================== -->

        <div >
          <v-img
            v-if="$vuetify.breakpoint.smAndDown"
            src="../assets/img/HowToUseSolAidMobile.png"
          />
          <v-img v-else src="../assets/img/HowToUseSolAid.png" width="100%" />
        </div>

    
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      isVisible: true,
    };
  },
  created() {
    window.addEventListener("scroll", this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.isVisible = window.scrollY > 400 ? false : true;
    },
    scrollGain() {
      window.scrollTo({
        top: 900,
        left: 0,
        behavior: "smooth",
      });
    },

   
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Libre Franklin";
  src: url("/src/assets/font/LibreFranklin-VariableFont_wght.ttf")
    format("truetype");
}

.taghireFont {
  display: block;
  font-family: "Libre Franklin" !important;
}
.protect_color {
  font-weight: bolder;
  background: -webkit-linear-gradient(
    180deg,
    rgba(74, 152, 11, 1),
    rgba(0, 117, 255, 1)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background: linear-gradient();
}

.textJustify {
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  opacity: 1 !important;
}

.middleText {
  position: absolute;
  left: 15.71%;
  right: 15.86%;
  top: 9.09%;
  bottom: 56.52%;
}

.textJustify_mobile {
  font-size: small;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  opacity: 1 !important;
}

.middleText_mobile {
  position: absolute;
  left: 15.71%;
  right: 15.86%;
  top: 5.09%;
  bottom: 80.52%;
}

.downText {
  position: absolute;
  left: 15.71%;
  right: 20.86%;
  top: 90vh;
  bottom: 0;
}
#btn2 {
  position: absolute;
  animation-name: example;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
@keyframes example {
  from {
    top: -15px;
  }
  to {
    top: 6px;
  }
}
</style>
