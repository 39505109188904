<template>
<div>
    <login  />
</div>
</template>

<script>
import Login from '@/components/Login.vue'
export default {
    name:'LoginView',
    components: {
        Login
    },
}
</script>

<style>

</style>
