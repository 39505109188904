import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView";

import MapView from "@/views/MapView";
import DashboardView from "@/views/DashboardView";
import AboutView from "@/views/AboutView";
import ContactUsView from "@/views/ContactUsView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // AboutUS
  {
    path: "/AboutUS",
    name: "AboutUS",
    component: AboutView,
  },
  {
    path: "/Login",
    name: "Login",
    component: LoginView,
  },

  {
    path: "/Map",
    name: "Map",
    component: MapView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/Dashboard/:role",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUsView,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
