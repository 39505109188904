<template>
  <div class="mt-12">
    <v-main>
      <v-card flat color="transparent">
        <v-container>
          <v-row class="align-center justify-center">
            <v-col>
              <v-breadcrumbs color="#FFC700" :items="items"></v-breadcrumbs>
            </v-col>
          </v-row>
        </v-container>
        <div>
          <v-img
            :src="
              require(`../assets/img/${
                $vuetify.breakpoint.mdAndUp ? 'contact' : 'contactUs_mobile'
              }.png`)
            "
            width="100%"
          >
            <div v-if="!$vuetify.breakpoint.mdAndUp">
              <v-container>
                <v-row>
                  <v-col align="center">
                    <br />
                    <br />
                    <h1>Contact Us</h1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <h3>
                      If you have any questions, please feel free to leave us a
                      message
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-img>
        </div>
        <v-container>
          <v-row class="justify-center align-center">
            <v-col align="center" cols="12">
              <h2 v-if="$vuetify.breakpoint.mdAndUp" class="font-weight-bold">
                If you have any questions, please feel free to leave us a
                message
              </h2>
            </v-col>

            <v-col align="center">
              <span>
                KSI contact you by phone or email to confirm your information
                and answer your question.
              </span>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rounded-xl" style="border: 1px solid #6a6262">
                <v-card flat color="transparent">
                  <v-card-text>
                    <!-- fields -->
                    <v-container>
                      <v-row>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <p class="ml-8">Name</p>
                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="name"
                            solo
                            background-color="#191919"
                            label="Name"
                            placeholder="Name"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <p class="ml-8">Phone number</p>
                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="phone"
                            solo
                            background-color="#191919"
                            label="Phone number"
                            placeholder="Phone number"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <p class="ml-8">Email</p>

                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="Email"
                            solo
                            background-color="#191919"
                            label="Email"
                            placeholder="Email"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <p class="ml-8">Company name</p>
                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="CompanyName"
                            solo
                            background-color="#191919"
                            label="Company name"
                            placeholder="Company name"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <p class="ml-8">Company Location</p>
                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="CompanyLocation"
                            solo
                            background-color="#191919"
                            label="please enter country/region"
                            placeholder="please enter country/region"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
                          <p class="ml-8 black--text">Company Location</p>
                          <v-text-field
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="address"
                            solo
                            background-color="#191919"
                            label="please enter full address"
                            placeholder="please enter full address"
                          ></v-text-field>
                        </v-col>

                        <v-col :cols="12">
                          <p class="ml-8">Problem Description</p>
                          <v-textarea
                            disabled
                            class="ml-5"
                            style="border-radius: 10px"
                            v-model="Description"
                            solo
                            background-color="#191919"
                            label="please enter description"
                            placeholder="please enter description"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center" class="mb-2">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            :style="
                              hover
                                ? 'border-radius: 10px; color: black;'
                                : ' border-radius: 10px;'
                            "
                            color="#FFC700"
                            width="85%"
                            :outlined="hover ? false : true"
                            @click="createOrder()"
                          >
                            save & apply</v-btn
                          >
                        </v-hover>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col align="center" cols="12" class="mt-5 mb-3">
              <h2>You can also contact us in the following ways:</h2>
            </v-col>

            <v-col cols="12" class="mb-8">
              <v-container>
                <v-row>
                  <v-card
                    v-for="(item, index) in contactList"
                    :key="index"
                    rounded="xl"
                    style="text-align: center"
                    :width="$vuetify.breakpoint.mdAndUp ? '255' : '190'"
                    :height="$vuetify.breakpoint.mdAndUp ? '200' : '160'"
                    :class="`${
                      $vuetify.breakpoint.mdAndUp ? '' : 'ma-5 col-md-2 '
                    } card2 ml-5 justify-center align-center`"
                  >
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col align="center">
                            <v-img
                              :width="$vuetify.breakpoint.mdAndUp ? '60' : '40'"
                              :height="
                                $vuetify.breakpoint.mdAndUp ? '60' : '40'
                              "
                              :src="require(`../assets/img/${item.image}.png`)"
                            ></v-img>

                            <span v-if="!$vuetify.breakpoint.mdAndUp">
                              {{ item.text }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-text v-if="$vuetify.breakpoint.mdAndUp"
                      ><span class="mb-5"> {{ item.text }}</span></v-card-text
                    >
                  </v-card>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ContactUsView",
  data() {
    return {
      Description: "",
      address: "",
      CompanyLocation: "",
      CompanyName: "",
      Email: "",
      phone: "",
      name: "",
      items: [
        {
          text: "Home",
          disabled: true,
          href: "/",
        },
        {
          text: "Contact us",
          disabled: false,
          href: "/ContactUs",
        },
      ],

      contactList: [
        { text: "help@KSI.solar", image: "email" },
        { text: "+41 (41) 508 7578 ", image: "calling" },
        {
          text: " Bergliweg 15, 6300 Zug, Switzerland ",
          image: "location",
        },
        { text: "Live chat(Whatsapp)", image: "whatsapp" },
      ],
    };
  },
  methods: {
    contactFormEmail() {
      let baseUrlSignUp = "http://65.108.91.247/api";
      let dt = {
        subject:
          "Contact : a user with this information wants to contact you .",
        description: this.Description,
        email: this.Email,
        name: this.name,
        phoneNumber: this.phone,
        companyLocation: this.companyLocation,
        companyName: this.companyName,
        address: this.address,
      };
      // /contact-us
      axios({
        method: "post",
        url: baseUrlSignUp + `/contact-us`,
        headers: {},
        data: dt,
      })
        .then(() => {
          this.$store.commit("showMessage", {
            content:
              "Your information is successfuly sent ! please Wait for contact",
            color: "success",
          });
        })
        .catch((e) => {
          console.log(e.message);
          this.$store.commit("showMessage", {
            content: "sry! somthing went wrong . try again in a few minutes .",
            color: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.card2:hover {
  background: linear-gradient(
    0deg,
    #000000 0%,
    rgba(0, 15, 32, 0.8) 0%,
    rgba(0, 117, 255, 0.5) 100%
  );
}
</style>
