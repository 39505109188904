<template>
  <v-container fluid>
    <v-card flat color="transparent" v-if="!$route.name.includes('Dashboard')">
      <v-card-text style="text-align: center">
        <h1 class="text-h3">Solar System Types</h1>
        <br />
      </v-card-text>
    </v-card>

<v-card flat color="transparent">
  <v-card-text>
    <v-container fluid>
      <v-row class="fill-height">
      <v-col
        class="col-md-3"
        v-for="(item, i) in urlsArray"
        :key="i"
        align="center"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            rounded="xl"
            style="text-align: left"
            :class="$vuetify.breakpoint.lgAndDown ? 'mx-2 mt-2' : 'mx-6 mt-2'"
            class="card1 fill-height"
            width="308"
          >
            <v-img
              height="206"
              :src="require(`../assets/img/${item.path}`)"
              :lazy-src="require(`../assets/img/${item.path}`)"
            ></v-img>

            <v-card-title  style="text-align: left">
              <span class="text-h5 font-weight-bold">{{ item.title }}</span>
              <span>{{item.short}}</span>
              </v-card-title>

            <v-card-text
              v-if="!$route.name.includes('Dashboard')"
              class="px-3 pa-5"
              >{{ item.text }}</v-card-text>

            <v-card-actions class="d_5 y_5 ">
              <v-btn
                @click="pickedAplan(item)"
                :style="
                  hover
                    ? 'border-radius: 10px; color: black;'
                    : ' border-radius: 10px;'
                "
                color="#FFC700"
                absolute
                bottom
                width="85%"
                :outlined="hover ? false : true"
              >
                Select</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    </v-container>
  </v-card-text>
</v-card>

   </v-container>

</template>

<script>
export default {
  name: "PlansPage",
  data: () => ({
    urlsArray: [
      {
        id: 1,
        title: "Ground Mounted",
        short: "(FGM)",
        value: "groundMounted",
        path: "Ksi_Ground-Mounted-Solar-Power-Plant.jpg",
        text: "Fixed Ground Mounted PV systems are mounted on a fixed support structure and do not move or track the sun's path. Fixed PV systems are commonly used for utility-scale solar energy projects, where large areas of land are available for the installation of solar panels.",
      },
      {
        id: 2,
        title: "Single Axis Tracker",
        short: "(SAT)",
        value: "singleAxisTracker",
        path: "ksi-Sosy-S_380.jpg",
        text: "Single-axis tracker systems are mounted on a support structure that moves on a single axis to track the sun's path throughout the day. These systems can maintain an optimal angle to the sun, which results in higher energy generation than FGM.",
      },
      {
        id: 3,
        title: "Roof Top",
        short: "\n",
        value: "roofTop",
        path: "Ksi_rooftop_solar.jpg",
        text: "Rooftop PV is installed on the roof of different buildings including residential, commercial, and industrial buildings to generate electricity in a sustainable and cost- effective way.",
      },
      {
        id: 4,
        title: "Dual  Axis Tracker ",
        short: "(DAT)",
        value: "dualAxisTracker",
        path: "Ksi-Dual_Axis_Traker.jpg",
        text: "Dual-axis tracker systems are mounted on a support structure that moves on two axes to track the sun's path. By tracking the sun's path in both horizontal and vertical directions, the solar panels can maintain an optimal angle to the sun throughout the year.",
      },
    ],
  }),
  methods: {
    pickedAplan(item) {
      this.$router.push({
        name: "Map",
        params: {
          item: item,
        },
      });
    },
  },
};
</script>

<style scoped>
.card1:hover {
  transform: scale(1.07);
  background: linear-gradient(
    0deg,
    #000000 0%,
    rgba(0, 15, 32, 0.8) 0%,
    rgba(0, 117, 255, 0.5) 100%
  );
}
</style>
