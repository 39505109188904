<template>
  <v-container
    fluid
    v-if="$route.path !== '/Login' && $route.name !== 'Dashboard'"
    :style="`
      position: fixed;
      left: 0;
      top: 0;
      ${
        $route.name === 'home' || $route.name === 'ContactUs'
          ? ' background-color: black; box-shadow: 10px 5px 5px #2E2E2E;'
          : ''
      }
      padding: 1.2rem;
    `"
  >
    <v-row class="align-center justify-center ml-12">
      <v-img
        src="../assets/img/ksg_white.png"
        max-width="100"
        width="80"
        @click="$router.push('/')"
      ></v-img>
      <!-- <h3 class="my_custom_orange">LOGO</h3> -->
      <v-btn
        class="text-right white--text"
        text
        link
        :ripple="false"
        @click="scBtn()"
        >About us</v-btn
      >
      <v-btn class="white--text" text :ripple="false" link to='/ContactUs'
        >Contact us</v-btn
      >

      <v-spacer></v-spacer>

      <div class="mr-12">
        <div v-if="Object.keys(this.user).length !== 0">
          <v-menu bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-avatar color="#FFC700" size="35">
                  <v-icon class="black--text text-h5" small
                    >mdi-account-outline</v-icon
                  >
                </v-avatar>
              </v-btn>
            </template>
            <v-card width="200px">
              <!-- {{ user }} -->
              <v-list-item-content class="justify-center">
                <div class="text-center">
                  <h3>{{ user.fullName }}</h3>
                  <p class="text-caption mt-1">
                    {{ user.email }}
                  </p>
                </div>
              </v-list-item-content>

              <v-divider></v-divider>

              <v-list-item-content>
                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    :color="hover ? '#FFC700' : 'white'"
                    depressed
                    :text="hover ? false : true"
                    :class="hover ? 'black--text' : 'white--text'"
                    @click="this.$store.dispatch('fetchAllUsersInfo')"
                    :to="{
                      name: 'Dashboard',
                      params: { role: user.roles },
                    }"
                  >
                    <!-- user.roles  -->
                    <v-icon class="mr-3"> mdi-account-outline </v-icon>
                    Dashboard
                  </v-btn>
                </v-hover>

                <v-hover v-slot="{ hover }">
                  <v-btn
                    block
                    :color="hover ? '#FFC700' : 'white'"
                    depressed
                    :text="hover ? false : true"
                    :class="hover ? 'black--text' : 'white--text'"
                    @click="LogOut()"
                  >
                    <v-icon class="mr-3"> mdi-logout </v-icon>
                    Log Out
                  </v-btn>
                </v-hover>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>

        <v-btn
          v-if="Object.keys(this.user).length === 0"
          color="#FFC700"
          small
          outlined
          :to="{ name: 'Login', params: { text: 'sign up' } }"
          rounded
        >
          sign up</v-btn
        >

        <v-btn
          v-if="Object.keys(this.user).length === 0"
          small
          color="#FFC700"
          :to="{ name: 'Login', params: { text: 'Login' } }"
          style="color: black"
          class="my_custom_orange ml-3"
          rounded
        >
          Log in</v-btn
        >
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NavSide",

  data: () => ({}),

  methods: {
    LogOut() {
      localStorage.clear();
      window.location.reload();
    },

    scBtn() {
      if (this.$route.name === "home") {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        this.$router.push("/").then(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      }
    },

    isLoggedIn() {
      this.$store.dispatch("fetchProfile");
    },
  },
  watch: {
    user() {
      return this.$store.state.profile || {};
    },
  },

  computed: {
    user() {
      return this.$store.state.profile || {};
    },
  },
  mounted() {
    if (Object.keys(this.user).length === 0) {
      this.isLoggedIn();
      // }else{
      //   console.log(Object.keys(this.user).length)
    }
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
