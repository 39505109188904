<template>
  <div>
    <v-card-title v-if="!showDetail">
      <v-container fluid>
        <v-row>
          <span>My Requests</span>
          <v-text-field
            v-if="$vuetify.breakpoint.mdAndUp"
            solo
            dense
            background-color="#191919"
            v-model="search"
            clearable
            color="#FFC700"
            clear-icon="mdi-close-circle"
            prepend-inner-icon="mdi-magnify"
            label="Search Request"
            placeholder="Search Request"
            class="mx-4"
            style="border-radius: 15px"
          ></v-text-field>
          <v-spacer></v-spacer>

          <div v-if="$vuetify.breakpoint.mobile">
            <v-btn
              class="mr-2"
              icon
              @click="showMobileSearch = !showMobileSearch"
            >
              <v-icon size="30">mdi-magnify</v-icon></v-btn
            >
          </div>

          <plans-choose />
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-title v-else>
      <v-btn text @click="showDetail = false">
        <span class="text-h5">
          <v-icon>mdi-arrow-left</v-icon>
          All Requests</span
        ></v-btn
      >
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text v-if="!showDetail">
      <v-text-field
        v-if="showMobileSearch"
        solo
        background-color="#191919"
        dense
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search "
        placeholder="Search"
        clearable
        clear-icon="mdi-close-circle"
        class="mx-4"
        color="#FFC700"
        style="border-radius: 15px"
      ></v-text-field>
      <v-data-table
        background-color="#191919"
        style="border-radius: 15px"
        :headers="headers"
        :items="reqList"
        :search="search"
        :hide-default-footer="reqList.lenght > 10"
      >
        <template #[`item.panelType`]="{ item }">
          <v-badge
            dot
            :color="getDotColor(item.panelType)"
            inline
            small
          ></v-badge>
          {{ item.panelType }}
        </template>

        <template #[`item.area`]="{ item }">
          <span v-if="item.area !== null">
            {{ item.area + "  " + item.areaUnit }}
          </span>
        </template>

        <template #[`item.capacity`]="{ item }">
          <span v-if="item.capacity !== null">
            {{ item.capacity.toFixed(2) + "  " + item.capacityUnit }}
          </span>
          <span v-else> - </span>
        </template>

        <template #[`item.energyPower`]="{ item }">
          <span v-if="item.energyPower !== null">
            {{ item.energyPower.toLocaleString() }}
          </span>
        </template>

        <template #[`item.lat`]="{ item }">
          <span v-if="item.lat !== null">
            {{ item.lat.toFixed(5) }}
          </span>
        </template>
        <template #[`item.lon`]="{ item }">
          <span v-if="item.lon !== null">
            {{ item.lon.toFixed(5) }}
          </span>
        </template>

        <template v-slot:[`item.quality`]="{ item }">
          <v-btn
            small
            depressed
            style="opacity: 0.9"
            width="90%"
            :color="getColor(item.quality)"
            dark
            rounded
          >
            {{ item.quality }}
          </v-btn>
        </template>

        <template v-slot:[`item.detail`]="{ item }">
          <v-btn fab icon small @click="LeadToDetail(item)">
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      <details-table
        v-on:DontShowDetail="showDetail = false"
        :detailData="detailData"
      />
    </v-card-text>
  </div>
</template>

<script>
import DetailsTable from "./DetailsTable.vue";
import PlansChoose from "./PlansChoose.vue";
export default {
  components: { DetailsTable, PlansChoose },
  data() {
    return {
      showMobileSearch: false,
      showDetail: false,
      search: "",
      // reqList:[],
      headers: [
        { text: "Date", value: "createdAt" },
        { text: "Solar System Type", value: "panelType" },
        { text: "Latitude", value: "lat" },
        { text: "Longitude", value: "lon" },
        { text: "Area", value: "area" },
        { text: "Capacity", value: "capacity" },
        { text: "Annual Energy Production(kWh)", value: "energyPower" },
        { text: "Result", value: "quality" },
        { text: "Detail", value: "detail", align: "end", sortable: false },
      ],

      detailData: {},
    };
  },
  computed: {
    reqList() {
      return this.$store.state.profile.orderHistory || [];
    },
  },
  methods: {
    getColor(res) {
      if (res === "Very poor") return "#822E27";
      else if (res === "Poor") return "#8C510C";
      else if (res === "Excellent") return "#266036";
      else if (res === "Good") return "#0C706A";
      else if (res === "Very Good") return "#7A8C0C";
      else return "#305480";
    },
    getDotColor(res) {
      if (res === "Single Axis Tracker") return "#FFC700";
      else if (res === "Dual Axis Tracker") return "#0075FF";
      else if (res === "Ground Mounted") return "green";
      else return "red";
    },
    LeadToDetail(item) {
      this.detailData = item;
      this.showDetail = true;
    },
  },

  created() {
    this.$store.dispatch("fetchProfile");
  },
};
</script>

<style></style>
