<template>
  <div>
    <map-select/>
  </div>
  
</template>

<script>
import MapSelect from '@/components/partial/MapSelect.vue'
export default {
  components: { MapSelect },
}
</script>

<style>

</style>