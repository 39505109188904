<template>
  <div>
    <v-app style="background-color: black" >
      <nav>
        <nav-stuff v-if="$vuetify.breakpoint.mdAndUp" />
        <nav-stuff-mobile v-else></nav-stuff-mobile>
      </nav>
      <snack-bar/>
      <router-view :style="$vuetify.breakpoint.mdAndUp ? 'position: relative' : ''"></router-view>
    </v-app>
  </div>
</template>

<script>
import NavStuff from "@/components/NavStuff";
import SnackBar from "./components/partial/SnackBar.vue";
import NavStuffMobile from "./components/NavStuffMobile.vue";

export default {
  components: {
    NavStuff,
    SnackBar,
    NavStuffMobile
},
};
</script>

<style lang="scss">

* , body , html {
  font-family: 'Poppins' , sans-serif;
}

@font-face {
  font-family: 'Poppins';
  src: url('/src/assets/font/Poppins-Regular.ttf') format('truetype');
}



nav {
  position: absolute;
  padding: 1.5rem;
  z-index: 500;
  background-color: transparent;
  right: 0;
  width: 100%;
}
</style>
