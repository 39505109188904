<template>
  <div>
    <v-card  flat color="transparent">
      <v-card-title>
        <v-tabs v-model="tab" :align-with-title="!$vuetify.breakpoint.mobile" background-color="transparent">
          <v-tabs-slider color="#FFC700"></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item.title"
            @click="item.name === 'admin'? admin = true  : admin = false ; $store.dispatch('fetchAllUsersInfo')"
          >
            {{ item.title }}
          </v-tab>

         
          <v-spacer></v-spacer>
          <div v-if="$vuetify.breakpoint.mobile">
            <v-btn icon class="mt-2"  @click="showMobileSearch = !showMobileSearch"><v-icon>mdi-magnify</v-icon></v-btn>
          </div>
    
          <v-text-field
            v-else
            solo
            background-color="#191919"
            dense
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search "
            placeholder="Search"
            class="mx-4"
            style="border-radius: 15px"
          ></v-text-field>
        </v-tabs>
      </v-card-title>
      <br   v-if="showMobileSearch"/>
      <v-text-field
      v-if="showMobileSearch"
   
      solo
      background-color="#191919"
      dense
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      label="Search "
      placeholder="Search"
      clearable
      clear-icon="mdi-close-circle"
      class="mx-4"
      color="#FFC700"
      style="border-radius: 15px"
    ></v-text-field>
      <v-data-table
        style="background-color: #191919; border-radius: 15px"
        :headers="headers"
        :items="admin ? AdminLists : UserLists"
        :loading="UserLists.length === 0 "
        :search="search"
        
      >
        <template #[`item.roles`]="{ item }">
          <v-btn small depressed @click="[dialog = true, selectedUser = item]" text dark>
            {{ item.roles }}
            <v-icon class="mx-2" small color="#FFC700">
              mdi-account-convert
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.contact`]="{ item }">
          <v-row  class="flex-nowrap">
          <v-btn
            small
            depressed
            dark
            outlined
            color="#0075FF"
            class="mx-2"
            :disabled="item.phoneNumber === 'phone number' || item.phoneNumber === '' || item.phoneNumber === null"
            @click="calling(item.phoneNumber)"
          >
            call</v-btn
          >

          <v-btn
            small
            depressed
            dark
            outlined
            color="#FFC700"
            @click="mailing(item.email)"
          >
            Email</v-btn
          >
          </v-row>
        </template>
      

      </v-data-table>
      <!-- dialog -->
      <v-dialog v-model="dialog" width="40%">
        <v-card>
          <v-card-title class="primary"> Confirmation </v-card-title>

          <v-card-text>
            <v-container class="text-center">
              <v-row>
                <v-col>
                  <span>
                    Are You Sure To Change  <span class="font-weight-bold ">{{selectedUser.fullName}}</span>'s  Role to {{ admin ? 'User' :'Admin ' }} ?
                  </span>
                </v-col>
              </v-row>
            </v-container> </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="error" text @click="dialog = false"> Deny </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="changeUser_AdminRole(selectedUser)">
              accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      showMobileSearch:false,
      tab: 0,
      dialog: false,
      admin:false,
      selectedUser:{},
      items: [
        { title: "All Users", name: "user" },
        { title: "Admins", name: "admin" },
      ],
      search: "",

      headers: [
        { text: "Date", value: "createdAt" },
        { text: "Name", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Role Defining", value: "roles" },
        { text: "Contact", value: "contact" },
        
      ],
    };
  },

  computed:{
    ...mapState({
      UserLists: state => state.usersInfo.usersInfo,
      AdminLists: state => state.usersInfo.adminsInfo,
    }),
  },

  methods: {
    mailing(item){
      window.open(`mailto:${item}`);
    },
    calling(item){
      window.open(`tel://${item}`);
    },
    changeUser_AdminRole(id) {
      const role = id.roles === "user" ? "admin" : "user";
      const us = {
        userId: id.userId,
        role: role,
      };

      this.$store.dispatch("changeUserRole", us).then(async () => {
        await this.$store.dispatch("fetchAllUsersInfo")
        this.dialog = false
       
      });
    },
 
  },
  beforeCreate() {
    this.$store.dispatch('fetchAllUsersInfo')
  },

};
</script>

<style></style>
