<template>
  <div>
    <v-tabs
      v-if="!showDetail"
      v-model="tab"
      :align-with-title="$vuetify.breakpoint.mdAndUp"
      background-color="transparent"
    >
      <v-tabs-slider color="#FFC700"></v-tabs-slider>

      <v-tab
        v-for="item in items"
        :key="item.title"
        @click="
          [
            item.name === 'mine' ? (mine = true) : (mine = false),
            $emit('All', all),
            (search = ''),
          ]
        "
      >
        {{ item.title }}
      </v-tab>

      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.mobile">
        <v-btn icon class="mt-2" @click="showMobileSearch = !showMobileSearch"
          ><v-icon>mdi-magnify</v-icon></v-btn
        >
      </div>

      <v-text-field
        v-else
        solo
        background-color="#191919"
        dense
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search "
        placeholder="Search"
        clearable
        clear-icon="mdi-close-circle"
        class="mx-4"
        color="#FFC700"
        style="border-radius: 15px"
      ></v-text-field>
    </v-tabs>

    <v-card-text v-if="!showDetail">
      <br v-if="showMobileSearch" />
      <v-text-field
        v-if="showMobileSearch"
        solo
        background-color="#191919"
        dense
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search "
        placeholder="Search"
        clearable
        clear-icon="mdi-close-circle"
        class="mx-4"
        color="#FFC700"
        style="border-radius: 15px"
      ></v-text-field>
      <v-data-table
        style="background-color: #191919; border-radius: 15px"
        :headers="headers"
        :items="mine ? myReq : allReq"
        :loading="allReq.length === 0"
        :search="
          selected.title !== 'All' ? selected.title || search : null || search
        "
      >
        <template #[`item.panelType`]="{ item }">
          <v-badge
            dot
            :color="getDotColor(item.panelType)"
            inline
            small
          ></v-badge>
          {{ item.panelType }}
        </template>

        <template #[`item.roles`]="{ item }">
          <div v-if="item.roles === 'admin'">
            <v-icon small color="#FFC700"> mdi-shield-account </v-icon>
          </div>
        </template>

        <template #[`item.capacity`]="{ item }">
          <span v-if="item.capacity !== null">
            {{ item.capacity.toFixed(2) }}
          </span>
          <span v-else> - </span>
        </template>

        <template #[`item.energyPower`]="{ item }">
          <span
            v-if="
              item.energyPower !== null && item.energyPower.slice(-3) === '.00'
            "
          >
            {{ Number(item.energyPower.slice(0, -3)).toLocaleString() }}
          </span>
        </template>

        <template v-slot:[`item.quality`]="{ item }">
          <v-btn
            small
            depressed
            style="opacity: 0.9"
            width="90%"
            :color="getColor(item.quality)"
            dark
            rounded
          >
            {{ item.quality }}
          </v-btn>
        </template>

        <template v-slot:[`item.contact`]="{ item }">
          <v-row class="flex-nowrap">
            <v-btn
              class="mr-2"
              color="#0075FF"
              outlined
              small
              :disabled="
                item.phoneNumber === 'phone number' ||
                item.phoneNumber === '' ||
                item.phoneNumber === null
              "
              @click="calling(item.phoneNumber)"
            >
              call
            </v-btn>

            <v-btn color="#FFC700" outlined small @click="mailing(item.email)">
              email
            </v-btn>
          </v-row>
        </template>

        <template v-slot:[`item.detail`]="{ item }">
          <v-btn
            fab
            icon
            small
            @click="[GoToDetails(item), $emit('showDetail', showDetail)]"
          >
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <div v-else>
      <details-table
        v-on:DontShowDetail="
          [(showDetail = false), $emit('showDetail', showDetail)]
        "
        :detailData="detailData"
      />
    </div>
  </div>
</template>

<script>
import DetailsTable from "../partial/DetailsTable.vue";
export default {
  components: { DetailsTable },
  props: {
    selected: Object,
  },
  data() {
    return {
      showMobileSearch: false,
      all: {
        id: 5,
        title: "All",
        color: "white",
        value: this.$store.state.orders.panelTypeCounter.all,
      },
      items: [
        { title: "All Request", name: "all" },
        { title: "My Requests", name: "mine" },
      ],
      mine: false,
      showDetail: false,
      search: "",
      tab: 0,
      detailData: {},
      headers: [
        { text: "", value: "roles" },
        { text: "Name", value: "fullName" },
        { text: "Solar System Type", value: "panelType" },
        {
          text: "Annual Energy Production(kWh)",
          value: "energyPower",
          align: "center",
        },
        { text: "Result", value: "quality", align: "center" },
        { text: "Date", value: "createdAt" },
        { text: "Contact", value: "contact", sortable: false },
        { text: "Detail", value: "detail", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    allReq() {
      return this.$store.state.orders.orders;
    },
    myReq() {
      return this.$store.state.profile.orderHistory;
    },
  },
  methods: {
    getColor(res) {
      if (res === "Very poor") return "#822E27";
      else if (res === "Poor") return "#8C510C";
      else if (res === "Excellent") return "#266036";
      else if (res === "Good") return "#0C706A";
      else if (res === "Very Good") return "#7A8C0C";
      else return "#305480";
    },
    getDotColor(res) {
      if (res === "Single Axis Tracker") return "#FFC700";
      else if (res === "Dual Axis Tracker") return "#0075FF";
      else if (res === "Ground Mounted") return "green";
      else return "red";
    },
    GoToDetails(item) {
      this.detailData = item;
      this.showDetail = true;
    },

    DontShowDetail() {
      this.showDetail = false;
      this.$emit("showDetail", this.showDetail);
    },
    mailing(item) {
      window.open(`mailto:${item}`);
    },
    calling(item) {
      console.log(item);
      window.open(`tel://${item}`);
    },
  },
  mounted() {
    this.$store.dispatch("fetchAllOrders");
  },
};
</script>

<style></style>
