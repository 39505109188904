<template>
  <v-main>
    <v-card flat :color="$vuetify.breakpoint.mobile ? 'transparent' : ''">
      <v-container v-if="!$vuetify.breakpoint.mdAndUp">
        <v-row class="align-center justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
              @click="$router.push('/')"
                v-bind="attrs"
                v-on="on"
                src="../../assets/img/KSI-original.png"
                width=" 120 "
                max-width="120"
                class="mt-3"
              ></v-img>
            </template>
            <span>Home</span>
          </v-tooltip>
          

      
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-else
        color="black"
        style="border-right: 1px solid #6a6262"
        fixed
        v-model="drawer"
        app
        width="8rem"
        class="justify-center align-center"
      >
        <template v-slot:prepend>
          <v-container fluid class="px-0">
            <v-row dense>
              <v-col>
                <v-list-item link class="text-h4" to="/">
                  <v-list-item-content
                    class="my_custom_orange justify-center align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-bind="attrs"
                          v-on="on"
                          src="../../assets/img/KSI-original.png"
                          max-width="100"
                          width="80"
                        ></v-img>
                      </template>
                      <span>Home</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template class="justify-center align-center">
          <div class="pa-2">
            <v-list style="margin-top: 10rem">
              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showProfile ? false : true"
                        small
                        :color="showProfile ? '#FFC700' : ''"
                        @click="appearProfile()"
                      >
                        <v-icon :color="showProfile ? 'black' : ''"
                          >mdi-account-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Profile</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showDataTable ? false : true"
                        small
                        :color="showDataTable ? '#FFC700' : ''"
                        @click="appearDataTable()"
                      >
                        <v-icon :color="showDataTable ? 'black' : ''"
                          >mdi-file-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Requests</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </div>
        </template>

        <template v-slot:append>
          <div class="pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab icon @click="LogOut()">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>

    <!-- detail table -->
    <v-card flat color="transparent" v-if="showDataTable">
      <v-container fluid>
        <v-card class="text--white" flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mobile"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <span class="text-h4 font-weight-bold">Dashboard</span>
          </v-card-title>
          <data-table></data-table>
        </v-card>
      </v-container>
    </v-card>

    <!-- profile -->

    <v-card flat color="transparent" v-if="showProfile">
      <v-container fluid>
        <v-card class="text--white" flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mobile"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <span class="text-h4 font-weight-bold">Profile</span>
          </v-card-title>
          <profile />
        </v-card>
      </v-container>
    </v-card>

    <v-bottom-navigation bottom fixed shift  class="text-center" 
    style="border-top: 1px solid #6a6262 ; background-color: black;"
     v-if="!$vuetify.breakpoint.mdAndUp">

     <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          :icon="showProfile ? false : true"
          small
          :color="showProfile ? '#FFC700' : ''"
          @click="appearProfile()"
        >
          <v-icon :color="showProfile ? 'black' : ''"
            >mdi-account-outline</v-icon
          >
        </v-btn>
      </template>
      <span>Profile</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          :icon="showDataTable ? false : true"
          small
          :color="showDataTable ? '#FFC700' : ''"
          @click="appearDataTable()"
        >
          <v-icon :color="showDataTable ? 'black' : ''"
            >mdi-file-outline</v-icon
          >
        </v-btn>
      </template>
      <span>Requests</span>
    </v-tooltip>


    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fab icon @click="LogOut()">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
     
    </v-bottom-navigation>
  </v-main>
</template>

<script>
import DataTable from "../partial/DataTable.vue";
import Profile from "./Profile.vue";

export default {
  components: { DataTable, Profile },
  name: "DashboardPage",
  data() {
    return {
      value: 1,
      showDataTable: true,
      showProfile: false,
      drawer: true,
    };
  },
  methods: {
    appearProfile() {
      this.showDataTable = false;
      this.showProfile = true;
    },
    appearDataTable() {
      this.showDataTable = true;
      this.showProfile = false;
    },

    LogOut() {
      localStorage.clear();
      this.$router.push("/");
      window.location.reload();
    },
  },
};
</script>

<style></style>
