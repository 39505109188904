<template>
  <v-footer dark padless>
    <v-card
      v-if="$vuetify.breakpoint.mdAndUp"
      width="100%"
      height="100%"
      flat
      class="footer flex px-9"
      tile
      
    >
      <v-card-title>
        <v-img src="../../assets/img/KSI-original.png" max-width="80"></v-img>

        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="px-9">
        <v-row>
          <v-col align="left">
            <div style="line-height: 3rem">
              <v-icon class="mr-5">mdi-email</v-icon> help@ksi.solar
              <br />
              <v-icon class="mr-5">mdi-phone</v-icon> +41 (41) 508 7578
              <br />
              <v-icon class="mr-5 flex-nowrap">mdi-map-marker-outline</v-icon>
              Bergliweg 15, 6300 Zug, Switzerland
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align="right">
            <v-col align="left">
              <v-row
                :class="$vuetify.breakpoint.lgAndUp ? 'align-center mb-2' : ''"
              >
                <span>Follow Us</span>
                <div v-for="icon in icons" :key="icon.id">
                  <v-btn
                    dark
                    icon
                    class="mx-1"
                    :href="icon.path"
                    target="_blank"
                  >
                    <v-icon size="24px" color="#FFC700">
                      {{ icon.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="mr-12 flex-nowrap align-left">
                <v-text-field
                  solo
                  background-color="blue-grey darken-3 "
                  dense
                  placeholder="Register Your Email"
                ></v-text-field>
                <v-btn class="ml-1" outlined color="#FFC700"> submit</v-btn>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" height="100%" flat class="footer" tile>
      <v-card-title class="text-center justify-center">
        <v-img src="../../assets/img/KSI-original.png" max-width="100"></v-img>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-row class="flex-nowrap mr-5 ml-5">
          <v-text-field
            solo
            background-color="blue-grey darken-3 "
            dense
            placeholder="Register Your Email"
          ></v-text-field>
          <v-btn class="ml-1" outlined color="#FFC700"> submit</v-btn>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" align="center">
            <span class="text-h6">Follow Us</span>
          </v-col>

          <div v-for="icon in icons" :key="icon.id">
            <v-btn dark icon class="mx-1" :href="icon.path" target="_blank">
              <v-icon size="24px" color="#FFC700">
                {{ icon.icon }}
              </v-icon>
            </v-btn>
          </div>
        </v-row>

        <v-row>
          <v-col align="left">
            <div style="line-height: 3rem">
              <v-icon class="mr-3">mdi-email</v-icon> ch@ksi.solar
              <br />
              <v-icon class="mr-3">mdi-phone</v-icon> +41 (41) 508 7578
              <br />
              <v-icon class="mr-3 flex-nowrap">mdi-map-marker-outline</v-icon>
              Bergliweg 15, 6300 Zug, Switzerland
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterPage",
  data: () => ({
    icons: [
      {
        id: 1,
        icon: "mdi-linkedin",
        path: "https://www.linkedin.com/company/ksi-solar",
      },
      {
        id: 2,
        icon: "mdi-instagram",
        path: "https://www.instagram.com/ksi.solar",
      },
      { id: 3, icon: "mdi-earth", path: "https://www.ksi.solar" },
      {
        id: 4,
        icon: "mdi-youtube",
        path: "https://www.youtube.com/@KSI-Solar",
      },
    ],
  }),
};
</script>

<style>
.footer {
  background: linear-gradient(180deg, #000e1e 1.05%, #013066 99.01%);
}
</style>
