import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueCountryCode from "vue-country-code-select";

Vue.use(VueCountryCode);


Vue.use(Vuetify);

export default new Vuetify({
 
    theme: { dark: true },
})



