<template>
  <div>
    <v-card-title>
      <span>Profile Information</span>
      <v-spacer></v-spacer>
      <v-btn
        :color="color1"
        style="border-radius: 10px"
        outlined
        @click="editProfile()"
      >
        save
      </v-btn>
    </v-card-title>
    <v-container class="text-left">
 
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <v-form ref="form">
            <tr>
              <td>
                <!-- <span style="color: red; position: absolute; bottom: 80%"
                      >*</span
                    > -->
                Email
              </td>
              <td>
                <v-text-field
                  disabled
                  class="ml-5"
                  style="border-radius: 10px"
                  v-model="email"
                  dense
                  solo
                  background-color="#191919"
                  label="Email"
                  placeholder="Email"
                ></v-text-field>
              </td>
            </tr>

            <tr>
              <td>
                <!-- <span style="color: red; position: absolute; bottom: 54%"
                      >*</span
                    > -->
                Name
              </td>
              <td>
                <v-text-field
                  class="ml-5"
                  v-model="name"
                  style="border-radius: 10px"
                  dense
                  solo
                  background-color="#191919"
                  :label="name ? '' : 'Name'"
                  :placeholder="name"
                ></v-text-field>
              </td>
            </tr>
          </v-form>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <v-form ref="form">
            <tr>
              <td>Phone number</td>
              <td>
                <v-row class="ml-4" no-gutters>
                  <v-col :cols="$vuetify.breakpoint.mobile ? 5 : 3">
                    <v-select
                      outlined
                      dense
                      style="border-radius: 10px"
                      v-model="country"
                      :items="states"
                      menu-props="auto"
                      label="Select Country"
                      hide-details
                      single-line
                      color="#FFC700"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      type="number"
                      :rules="NumRules"
                      hide-spin-buttons
                      :persistent-hint="
                        number.length !== 10 || number.startsWith('0')
                      "
                      counter="10"
                      :color="
                        number.length !== 10
                          ? 'red lighten-2'
                          : 'green lighten-2'
                      "
                      :hint="
                        number.startsWith('0')
                          ? 'please dont start with 0 !\n example : 91********'
                          : ''
                      "
                      class="ml-5"
                      v-model="number"
                      style="border-radius: 10px"
                      dense
                      solo
                      :background-color="
                        number
                          ? number.length !== 10
                            ? 'red lighten-2'
                            : 'green lighten-2'
                          : '#191919'
                      "
                      :label="number ? '' : 'Phone Number'"
                      :placeholder="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
            </tr>

            <tr>
              <td>Company Name</td>
              <td>
                <v-text-field
                  class="ml-5"
                  v-model="companyName"
                  style="border-radius: 10px"
                  dense
                  solo
                  background-color="#191919"
                  :label="companyName ? '' : 'Company Name'"
                  :placeholder="companyName"
                ></v-text-field>
              </td>
            </tr>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <!-- pass change -->
    <div>
      <v-container fluid>
        <v-row align="center" justify="start">
          <span
            class="text-h6 mt-5 mb-5"
            :class="$vuetify.breakpoint.mobile ? 'ma-5 ' : ''"
            style="color: #ffc700"
          >
            Changing password</span
          >

          <v-divider
            style="color: #ffc700; border: 1px solid; margin-left: 2%"
            :style="
              $vuetify.breakpoint.mobile
                ? 'margin-right: 5%'
                : 'margin-right: 50%;'
            "
          ></v-divider>
        </v-row>
        <v-row>
          <v-col :cols="$vuetify.breakpoint.mobile ? 7 : 8">
            <tr>
              <td v-if="!$vuetify.breakpoint.mobile">Password</td>
              <td>
                <v-text-field
                  :class="$vuetify.breakpoint.mobile ? '' : 'ml-5'"
                  v-model="pass"
                  style="border-radius: 10px"
                  full-width
                  dense
                  solo
                  background-color="#191919"
                  :label="
                    showFileds ? 'Enter the current password' : 'Password'
                  "
                  :placeholder="
                    showFileds ? 'Enter the current password' : 'Password'
                  "
                ></v-text-field>
              </td>
            </tr>

            <tr v-if="showFileds">
              <td v-if="!$vuetify.breakpoint.mobile">New Password</td>
              <td>
                <v-text-field
                  :class="$vuetify.breakpoint.mobile ? '' : 'ml-5'"
                  v-model="Newpass"
                  style="border-radius: 10px"
                  dense
                  solo
                  background-color="grey darken-3"
                  label="New Password"
                  placeholder="New Password"
                ></v-text-field>
              </td>
            </tr>
          </v-col>
          <v-col align="right" :cols="$vuetify.breakpoint.mobile ? 5 : 4">
            <v-btn
              style="border-radius: 10px"
              @click="showFileds = !showFileds"
              outlined
              :small="!$vuetify.breakpoint.mobile"
              :disabled="Newpass.length > 0"
              color="#0075FF"
            >
              change
              <span v-if="!$vuetify.breakpoint.mobile" style="color: #0075ff">
                password</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ProfilePage",
  data() {
    return {
      showFileds: false,
      country: "+98",
      email: "",
      name: "",
      number: "",
      companyName: "",
      pass: "",
      Newpass: "",
      icon: "mdi-pencil",
      color1: "#FFC700",
      states: ["+98", "+39", "+1"],
      NumRules: [
        (v) => v.length === 10 || "Number must be 10 digits",
        (v) => !v.startsWith("0") || "Please dont start with 0",
      ],
    };
  },
  methods: {
    async setData() {
      const fetchData = this.$store.state.profile;
      this.email = fetchData.email;
      this.name = fetchData.fullName;
      this.number = fetchData.phone.number;
      this.country = fetchData.phone.code;
      this.companyName = fetchData.companyName;
    },
    editProfile() {
      if (this.$refs.form.validate()) {
        axios({
          method: "patch",
          url: "http://65.108.91.247/api/users/profile",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          data: {
            currentPassword: this.pass,
            fullName: this.name,
            phoneNumber: this.country + this.number,
            companyName: this.companyName,
            newPassword: this.Newpass,
          },
        })
          .then((res) => {
            this.icon = "mdi-check";
            this.color1 = "success";
            setTimeout(() => {
              this.$store.dispatch("fetchProfile");
              this.$store.commit("showMessage", {
                content: res.data.message,
                color: "success",
              }),
                (this.icon = "mdi-pencil"),
                (this.color1 = "#FFC700");
            }, 2000);
            if (this.Newpass.length > 0) {
              this.LogOut();
            }
          })
          .catch((e) => {
            this.$store.commit("showMessage", {
              content: e.response.data.message,
              color: "error",
            });
          });
      }
    },
    LogOut() {
      setTimeout(() => {
        localStorage.clear();
        this.$router.push("/");
        window.location.reload();
      }, 2000);
      this.$store.commit("showMessage", {
        content: "You Have to Login Again ! Sorry ...",
        color: "warning",
      });
    },
  },
  mounted() {
    if (this.$store.state.profile !== {}) {
      this.setData();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
