<template>
  <v-container fluid class="mt-8">
    <v-row align="center" justify="space-around" dense>
      <!-- first card -->
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
        <v-card flat rounded="lg" color="transparent">
          <v-card-title> Daily Request </v-card-title>
          <v-card-text>
            <v-card flat rounded="lg">
              <div id="chartdiv"></div>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- second card -->
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12">
        <v-card flat rounded="lg" color="transparent">
          <v-card-title> All Request </v-card-title>
          <v-card-text>
            <v-card flat rounded="lg">
              <div id="chartdiv2"></div>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  data() {
    return {
      allReq1: [],
    };
  },
  computed: {
    titles() {
      return [
        {
          id: 1,
          title: "Single Axis Tracker",
          color: "#FFC700",
          value: this.$store.state.orders.panelTypeCounter.singleAxisTracker,
        },
        {
          id: 2,
          title: "Dual Axis Traker",
          color: "#0075FF",
          value: this.$store.state.orders.panelTypeCounter.dualAxisTracker,
        },
        {
          id: 3,
          title: "Ground Mounted",
          color: "green",
          value: this.$store.state.orders.panelTypeCounter.groundMounted,
        },
        {
          id: 4,
          title: "Roof Top",
          color: "red",
          value: this.$store.state.orders.panelTypeCounter.roofTop,
        },

        {
          id: 5,
          title: "All",
          color: "white",
          value: this.$store.state.orders.panelTypeCounter.all || 1,
        },
      ];
    },
  },

  methods: {
    pieCHartFunc() {
      const chart = am4core.create("chartdiv", am4charts.PieChart);

      // Add data
      chart.data = this.titles.slice(0, 4);

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "title";
      pieSeries.labels.template.fill = am4core.color("white");
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      chart.radius = am4core.percent(65);
      chart.legend = new am4charts.Legend();
      chart.legend.labels.template.fill = am4core.color("#ffffff");
      chart.legend.valueLabels.template.fill = am4core.color("#ffffff");
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);

      pieSeries.colors.list = [
        am4core.color("#FFC700"),
        am4core.color("#0075FF"),
        am4core.color("green"),
        am4core.color("red"),
      ];
    },
    xyChartFunc() {
      this.allReq1.sort(function (a, b) {
        return new Date(b._id) - new Date(a._id);
      });

      const data = this.allReq1.filter(i=>i._id = new Date(i._id).toDateString()).reverse();

      am5.ready(function () {
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("chartdiv2");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
          })
        );

        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
          rotation: -50,
          centerY: am5.p50,
          centerX: am5.p100,
          paddingRight: 3,
        });

        xRenderer.grid.template.setAll({
          location: 1,
        });

        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "_id",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
          })
        );

        var yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(root, {
              strokeOpacity: 0.1,
            }),
          })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "count",
            sequencedInterpolation: true,
            categoryXField: "_id",
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueY}",
            }),
          })
        );

        series.columns.template.setAll({
          cornerRadiusTL: 5,
          cornerRadiusTR: 5,
          strokeOpacity: 0,
        });
       
        root.interfaceColors.set("text", am5.color(0xffffff));

        xAxis.data.setAll(data);
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);
      });
    },
  },

  mounted() {
    this.$store.dispatch("getAllOrderCounter");
    this.$store.dispatch("getsPanelTypesValues").then(() => {
      this.allReq1 = this.$store.state.orders.allOrderCounter || [];
      this.pieCHartFunc();
      this.xyChartFunc();
    });
  },
};
</script>

<style>
#chartdiv {
  width: 100%;
  height: 400px;
}
#chartdiv2 {
  width: 100%;
  height: 400px;
}
</style>
