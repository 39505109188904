<template>
    <v-snackbar v-model="show" :color="color" timeout="4000" top class="pt-8">
      {{ message }}
      <template #action="{ attrs }">
        <v-btn icon small v-bind="attrs" @click="show = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </template>
  
  <script>
  export default {
    data() {
      return {
        show: false,
        message: '',
        color: '',
      }
    },
  
    created() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'showMessage') {
          this.message = state.snackbar.content
          this.color = state.snackbar.color
          this.show = true
        }
      })
    },
  }
  </script>