var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.path !== '/Login' && _vm.$route.name !== 'Dashboard')?_c('v-container',{style:(`
    position: fixed;
    left: 0;
    top: 0;
    ${
      _vm.$route.name === 'home' || _vm.$route.name === 'ContactUs'
        ? ' background-color: black; box-shadow: 10px 5px 5px #2E2E2E;'
        : ''
    }
    padding: 1.2rem;
  `),attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center justify-center ml-12"},[_c('v-img',{attrs:{"src":require("../assets/img/ksg_white.png"),"max-width":"100","width":"80"},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('v-btn',{staticClass:"text-right white--text",attrs:{"text":"","link":"","ripple":false},on:{"click":function($event){return _vm.scBtn()}}},[_vm._v("About us")]),_c('v-btn',{staticClass:"white--text",attrs:{"text":"","ripple":false,"link":"","to":"/ContactUs"}},[_vm._v("Contact us")]),_c('v-spacer'),_c('div',{staticClass:"mr-12"},[(Object.keys(this.user).length !== 0)?_c('div',[_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-avatar',{attrs:{"color":"#FFC700","size":"35"}},[_c('v-icon',{staticClass:"black--text text-h5",attrs:{"small":""}},[_vm._v("mdi-account-outline")])],1)],1)]}}],null,false,4125716828)},[_c('v-card',{attrs:{"width":"200px"}},[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.user.fullName))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])])]),_c('v-divider'),_c('v-list-item-content',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{class:hover ? 'black--text' : 'white--text',attrs:{"block":"","color":hover ? '#FFC700' : 'white',"depressed":"","text":hover ? false : true,"to":{
                    name: 'Dashboard',
                    params: { role: _vm.user.roles },
                  }},on:{"click":function($event){return this.$store.dispatch('fetchAllUsersInfo')}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-account-outline ")]),_vm._v(" Dashboard ")],1)]}}],null,false,606421239)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{class:hover ? 'black--text' : 'white--text',attrs:{"block":"","color":hover ? '#FFC700' : 'white',"depressed":"","text":hover ? false : true},on:{"click":function($event){return _vm.LogOut()}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" mdi-logout ")]),_vm._v(" Log Out ")],1)]}}],null,false,1143686375)})],1)],1)],1)],1):_vm._e(),(Object.keys(this.user).length === 0)?_c('v-btn',{attrs:{"color":"#FFC700","small":"","outlined":"","to":{ name: 'Login', params: { text: 'sign up' } },"rounded":""}},[_vm._v(" sign up")]):_vm._e(),(Object.keys(this.user).length === 0)?_c('v-btn',{staticClass:"my_custom_orange ml-3",staticStyle:{"color":"black"},attrs:{"small":"","color":"#FFC700","to":{ name: 'Login', params: { text: 'Login' } },"rounded":""}},[_vm._v(" Log in")]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }