import Vue from "vue";
import Vuex from "vuex";
import snackbar from "@/store/modules/snackbar";
import userData from "./modules/userData";
import axios from "axios";
import router from "@/router";
import usersInfo from "./modules/usersInfo";
import orders from "./modules/orders";

Vue.use(Vuex);
const baseUrl = "http://65.108.91.247/api";

export default new Vuex.Store({
  state: {
    profile: {},
    colors: {
      warning: "#FFC700",
      blue: "#0075FF",
    },
  },
  getters: {},
  mutations: {
    setProfile: (state, dt) => (state.profile = dt),
  },
  actions: {
    async fetchProfile({ commit }) {
      const me = localStorage.getItem("token");
      if (me) {
        await axios({
          method: "GET",
          url: baseUrl + "/users/profile",
          headers: {
            Authorization: `Bearer ${me}`,
            Accept: "application/json",
          },
        })
          .then((res) => {
            commit("setProfile", res.data);
          })
          .catch((e) => {
            if (
              e.response.status === 406 ||
              e.response.status === 401 ||
              e.response.status === 409
            ) {
              router.push({
                name: "Login",
                params: {
                  text: "Login",
                },
              });
            } else {
              commit("showMessage", {
                content: e.response.data.message,
                color: "error",
              });
            }
          });
      }
    },
  },
  modules: {
    snackbar,
    userData,
    usersInfo,
    orders,
  },
});
