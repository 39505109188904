<template>
    <v-container v-if="$route.path !== '/Login' && $route.name !== 'Dashboard'">
      <v-row >
        <v-btn icon @click="drawer = !drawer"><v-icon>mdi-menu</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-img
          src="../assets/img/ksg_white.png"
          max-width="100"
          width="80"
          @click="$router.push('/')"
        ></v-img>
      </v-row>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        color="black"
        fixed
        left
        temporary
        height="100vh"
      >
        <template v-slot:prepend>
          <v-container fluid class="px-0 py-0">
            <v-row dense>
              <v-col>
                <v-list-item class="navbar-logo">
                  <v-list-item-content>
                    <v-list-item-title class="justify-center d-flex">
                      <v-container>
                        <v-row>
                          <v-img
                            src="../assets/img/ksg_white.png"
                            max-width="100"
                            width="80"
                            @click="$router.push('/')"
                          ></v-img>
                          <v-spacer></v-spacer>

                          <v-tooltip
                          v-if="Object.keys(user).length !== 0"
                            bottom
                            color="#FFC700"
                          >
                            <template #activator="{ on, attrs }">
                              <v-btn
                             
                                icon
                                color="#FFC700"
                                v-bind="attrs"
                                v-on="on"
                                @click="LogOut()"
                              >
                                <v-icon> mdi-logout </v-icon>
                              </v-btn>
                            </template>
                            <span class="black--text">Log Out</span>
                          </v-tooltip>
                          
                        </v-row>
                      </v-container>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="mt-4 mb-4">
                  <v-list-item-title>
                    <v-container>
                      <v-row>
                        <v-btn icon>
                          <v-avatar color="#FFC700" size="35">
                            <v-icon class="black--text text-h5" small
                              >mdi-account-outline</v-icon
                            >
                          </v-avatar>
                        </v-btn>
                        <div
                          class="text-left ml-3"
                          v-if="Object.keys(user).length !== 0"
                        >
                          <h3>
                            {{ user.fullName }}
                          </h3>
                          <p class="text-caption mt-1">
                            {{ user.email }}
                          </p>
                        </div>

                        <v-btn
                          v-else
                          rounded
                          outlined
                          color="#FFC700"
                          class="ml-3"
                          :to="{
                            name: 'Login',
                            params: { text: 'Login' },
                          }"
                        >
                          sign up / Login
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-list-item-title>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <div
          :style="{
            color: '#FFC700',
            width: '100%',
            height: '.1rem',
            border: ' 1px solid',
            margin: ' 0 1rem ',
          }"
        ></div>
        <!-- lists -->
        <v-list nav class="mt-8">
          <v-list-item-group>
            <v-list-item
              v-if="Object.keys(user).length !== 0"
              @click="this.$store.dispatch('fetchAllUsersInfo')"
              :to="
                user.roles
                  ? {
                      name: 'Dashboard',
                      params: { role: user.roles },
                    }
                  : {
                      name: 'Login',
                      params: { text: 'Login' },
                    }
              "
            >
              <v-icon class="mr-3"> mdi-account-outline </v-icon>
              Dashboard
            </v-list-item>

            <v-list-item
              :to="{
                name: 'AboutUS',
              }"
            >
              <v-icon class="mr-3"> mdi-information-outline </v-icon>
              About Us
            </v-list-item>

            <v-list-item to="/ContactUs">
              <v-list-item-title>
                <v-icon class="mr-3"> mdi-phone-outline </v-icon>

                Contact Us</v-list-item-title
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-container>
          <v-row>
            <v-col align="left">
              <v-btn  v-if="Object.keys(user).length !== 0"
                width="90%"
                absolute
                bottom
                color="#FFC700"
                outlined
                @click="scBtn()"
              >
                Submit an Order
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-navigation-drawer>
    </v-container>
</template>

<script>
export default {
  name: "NavStuffMobile",

  data: () => ({
    drawer: false,
  }),

  methods: {
    LogOut() {
      localStorage.clear();
      window.location.reload();
    },

    scBtn() {
      if (this.$route.name === "home") {
        window.scrollTo({
          top: 1900,
          left: 0,
          behavior: "smooth",
        });
      } else {
        this.$router.push("/").then(async () => {
          await window.scrollTo({
            top: 1900,
            left: 0,
            behavior: "smooth",
          });
        });
      }
    },
    isLoggedIn() {
      this.$store.dispatch("fetchProfile");
    },
  },
  watch: {
    user() {
      return this.$store.state.profile || {};
    },
  },

  computed: {
    user() {
      return this.$store.state.profile || {};
    },
  },
  mounted() {
    if (Object.keys(this.user).length === 0) {
      this.isLoggedIn();
    }
  },
};
</script>

<style></style>
