<template>
  <div >
    <v-row class="d-flex">
      <div v-for="item in titles" :key="item.id" class="flex-fill">
        <!--  width="300" -->
        <v-card
          class="ma-2"
          :width="!$vuetify.breakpoint.mobile ? '15rem' : '9rem'"
          :height="!$vuetify.breakpoint.mobile ? '' : '12rem'"
          rounded="lg"
          @click="getItem(item)"
        >
          <v-card-title>
            <span  style="word-break: keep-all; ">{{ item.title }}</span>
         
          </v-card-title>

          <v-card-text class="text-left">
            <span class="font-weight-bold text-h4">
              {{ item.value }}
            </span>
          </v-card-text>
          <v-card-actions class="ma-2">
          
            <div
              :style="{
                color: item.color,
                width: '100%',
                height: '.1rem',
                border: ' 2px solid',
              }"
            ></div>
          </v-card-actions>
        </v-card>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    titles: Array,
  },
  methods: {
    getItem(item) {
      this.$emit("selectedItem", item);
    },
  },
};
</script>

<style></style>
