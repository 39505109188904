var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-img',{attrs:{"src":_vm.$vuetify.breakpoint.mdAndUp
          ? ''
          : require('/src/assets/img/login-mobile.png')}},[_c('v-row',[_c('v-card',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndUp ? 'black' : 'transparent',"width":"100%","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-5' : '',attrs:{"align":_vm.$vuetify.breakpoint.mdAndUp ? 'left' : 'center',"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'ma-5' : '',attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp ? false : true}},[_c('v-card',{attrs:{"flat":"","color":_vm.$vuetify.breakpoint.mdAndUp ? 'black' : 'transparent'}},[_c('v-card-title',{staticClass:"justify-center"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-img',{attrs:{"src":require("../assets/img/KSI-original.png"),"max-width":"150"}}):_c('v-img',{attrs:{"src":require("../assets/img/ksg_white.png"),"max-width":"120"}})],1),_c('br'),_c('v-card-text',[_c('h2',{class:_vm.$vuetify.breakpoint.mdAndUp
                            ? 'text-h2 font-weight-bold text--white'
                            : 'text-h4 font-weight-bold text--white'},[_vm._v(" Welcome "),(_vm.$route.params.text === 'Login')?_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
                              ? ''
                              : 'text-h4 font-weight-bold'},[_vm._v("Back")]):_vm._e()]),_c('br'),(_vm.$route.params.text === 'Login')?_c('h4',[_vm._v(" Login to your account ")]):_c('h4',[_vm._v("Create your account")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.letsGo.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticStyle:{"border-radius":"15px"},attrs:{"color":"#FFC700","disabled":_vm.forgetPass || _vm.showOtp,"outlined":_vm.$vuetify.breakpoint.mdAndDown,"prepend-inner-icon":"mdi-email","rules":[_vm.rules.required, _vm.rules.email],"solo":"","background-color":_vm.$vuetify.breakpoint.mdAndUp
                              ? '#191919'
                              : '#000000',"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.$route.params.text === 'sign up')?_c('v-text-field',{staticStyle:{"border-radius":"15px"},attrs:{"color":"#FFC700","outlined":_vm.$vuetify.breakpoint.mdAndDown,"disabled":_vm.showOtp,"prepend-inner-icon":"mdi-account","rules":[_vm.rules.required],"solo":"","background-color":_vm.$vuetify.breakpoint.mdAndUp
                              ? '#191919'
                              : '#000000',"label":"Full Name","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}):_vm._e(),_c('v-text-field',{staticStyle:{"border-radius":"15px"},attrs:{"color":"#FFC700","disabled":_vm.showOtp,"prepend-inner-icon":"mdi-lock","outlined":_vm.$vuetify.breakpoint.mdAndDown,"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.$route.params.text === 'Login'
                              ? [_vm.rules.required]
                              : [_vm.rules.required, _vm.rules.min],"type":_vm.show1 ? 'text' : 'password',"label":"Password","solo":"","background-color":_vm.$vuetify.breakpoint.mdAndUp
                              ? '#191919'
                              : '#000000',"hint":_vm.$route.params.text === 'Login'
                              ? ''
                              : 'At least 8 characters',"counter":_vm.$route.params.text === 'Login' ? false : true,"required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-row',{staticClass:"ml-2 mt-3",attrs:{"align":"center","dense":""}},[_c('v-spacer'),(_vm.$route.params.text === 'Login')?_c('v-btn',{attrs:{"disabled":_vm.showOtp,"x-small":"","color":_vm.$vuetify.breakpoint.mdAndUp
                                ? 'primary'
                                : 'white',"text":""},on:{"click":function($event){return _vm.forgetPassFunc()}}},[_vm._v("Forget Password ?")]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOtp),expression:"showOtp"}],staticClass:"ma-auto position-relative",staticStyle:{"max-width":"400px"}},[_c('div',[(_vm.showOtp)?_c('flip-countdown',{attrs:{"deadline":_vm.countDown.toLocaleString(),"showDays":false,"showHours":false},on:{"timeElapsed":_vm.timeElapsedHandler}}):_vm._e()],1),_c('v-otp-input',{attrs:{"disabled":_vm.loading,"type":"number"},on:{"finish":_vm.onFinish},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('span',{staticClass:"mt-5"},[_vm._v(" Enter verfication number that has sent to your E-mail ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":_vm.otp === ''},on:{"click":function($event){[(_vm.otp = ''), (_vm.loading = false)]}}},[_vm._v("Empty Otp")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"success","disabled":_vm.resendCode},on:{"click":function($event){_vm.forgetPass
                                  ? _vm.forgetPassFunc()
                                  : [(_vm.otp = ''), (_vm.loading = false), _vm.getOtp()]}}},[_vm._v("resend code")])],1)],1)])],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showOtp),expression:"!showOtp"}],class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'black--text',style:(hover
                              ? 'border-radius: 10px; color: black;'
                              : 'border-radius: 10px;'),attrs:{"disabled":!_vm.valid,"outlined":_vm.$vuetify.breakpoint.mdAndUp
                              ? hover
                                ? false
                                : true
                              : false,"color":"#FFC700","loading":_vm.btnLoading,"type":"submit","block":""},on:{"click":function($event){return _vm.letsGo()}}},[_vm._v(" "+_vm._s(_vm.$route.params.text))])]}}])})],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-divider',{staticClass:"mr-2"}),_vm._v(" Or "),_c('v-divider',{staticClass:"ml-2"})],1)],1),_c('v-card-actions',{staticClass:"text-center justify-center"},[(_vm.$route.params.text === 'sign up')?_c('h4',[_vm._v(" Already have an account ? "),_c('v-btn',{attrs:{"x-small":"","text":"","color":"#FFC700"},on:{"click":function($event){return _vm.goToSignUPOrLogin('Login')}}},[_vm._v(" Login")])],1):_c('h4',[_vm._v(" Dont have an account ? "),_c('v-btn',{attrs:{"x-small":"","text":"","color":"#FFC700"},on:{"click":function($event){return _vm.goToSignUPOrLogin('sign up')}}},[_vm._v(" sign up")])],1)])],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"r-5",attrs:{"height":"100%","src":require("../assets/img/login_photo.png")}})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }