var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',[_c('span',[_vm._v("Profile Information")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"border-radius":"10px"},attrs:{"color":_vm.color1,"outlined":""},on:{"click":function($event){return _vm.editProfile()}}},[_vm._v(" save ")])],1),_c('v-container',{staticClass:"text-left"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 6}},[_c('v-form',{ref:"form"},[_c('tr',[_c('td',[_vm._v(" Email ")]),_c('td',[_c('v-text-field',{staticClass:"ml-5",staticStyle:{"border-radius":"10px"},attrs:{"disabled":"","dense":"","solo":"","background-color":"#191919","label":"Email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('tr',[_c('td',[_vm._v(" Name ")]),_c('td',[_c('v-text-field',{staticClass:"ml-5",staticStyle:{"border-radius":"10px"},attrs:{"dense":"","solo":"","background-color":"#191919","label":_vm.name ? '' : 'Name',"placeholder":_vm.name},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)])])],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 6}},[_c('v-form',{ref:"form"},[_c('tr',[_c('td',[_vm._v("Phone number")]),_c('td',[_c('v-row',{staticClass:"ml-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 5 : 3}},[_c('v-select',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","dense":"","items":_vm.states,"menu-props":"auto","label":"Select Country","hide-details":"","single-line":"","color":"#FFC700"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"ml-5",staticStyle:{"border-radius":"10px"},attrs:{"type":"number","rules":_vm.NumRules,"hide-spin-buttons":"","persistent-hint":_vm.number.length !== 10 || _vm.number.startsWith('0'),"counter":"10","color":_vm.number.length !== 10
                        ? 'red lighten-2'
                        : 'green lighten-2',"hint":_vm.number.startsWith('0')
                        ? 'please dont start with 0 !\n example : 91********'
                        : '',"dense":"","solo":"","background-color":_vm.number
                        ? _vm.number.length !== 10
                          ? 'red lighten-2'
                          : 'green lighten-2'
                        : '#191919',"label":_vm.number ? '' : 'Phone Number',"placeholder":_vm.number},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1)],1)]),_c('tr',[_c('td',[_vm._v("Company Name")]),_c('td',[_c('v-text-field',{staticClass:"ml-5",staticStyle:{"border-radius":"10px"},attrs:{"dense":"","solo":"","background-color":"#191919","label":_vm.companyName ? '' : 'Company Name',"placeholder":_vm.companyName},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})],1)])])],1)],1)],1),_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('span',{staticClass:"text-h6 mt-5 mb-5",class:_vm.$vuetify.breakpoint.mobile ? 'ma-5 ' : '',staticStyle:{"color":"#ffc700"}},[_vm._v(" Changing password")]),_c('v-divider',{staticStyle:{"color":"#ffc700","border":"1px solid","margin-left":"2%"},style:(_vm.$vuetify.breakpoint.mobile
              ? 'margin-right: 5%'
              : 'margin-right: 50%;')})],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 7 : 8}},[_c('tr',[(!_vm.$vuetify.breakpoint.mobile)?_c('td',[_vm._v("Password")]):_vm._e(),_c('td',[_c('v-text-field',{class:_vm.$vuetify.breakpoint.mobile ? '' : 'ml-5',staticStyle:{"border-radius":"10px"},attrs:{"full-width":"","dense":"","solo":"","background-color":"#191919","label":_vm.showFileds ? 'Enter the current password' : 'Password',"placeholder":_vm.showFileds ? 'Enter the current password' : 'Password'},model:{value:(_vm.pass),callback:function ($$v) {_vm.pass=$$v},expression:"pass"}})],1)]),(_vm.showFileds)?_c('tr',[(!_vm.$vuetify.breakpoint.mobile)?_c('td',[_vm._v("New Password")]):_vm._e(),_c('td',[_c('v-text-field',{class:_vm.$vuetify.breakpoint.mobile ? '' : 'ml-5',staticStyle:{"border-radius":"10px"},attrs:{"dense":"","solo":"","background-color":"grey darken-3","label":"New Password","placeholder":"New Password"},model:{value:(_vm.Newpass),callback:function ($$v) {_vm.Newpass=$$v},expression:"Newpass"}})],1)]):_vm._e()]),_c('v-col',{attrs:{"align":"right","cols":_vm.$vuetify.breakpoint.mobile ? 5 : 4}},[_c('v-btn',{staticStyle:{"border-radius":"10px"},attrs:{"outlined":"","small":!_vm.$vuetify.breakpoint.mobile,"disabled":_vm.Newpass.length > 0,"color":"#0075FF"},on:{"click":function($event){_vm.showFileds = !_vm.showFileds}}},[_vm._v(" change "),(!_vm.$vuetify.breakpoint.mobile)?_c('span',{staticStyle:{"color":"#0075ff"}},[_vm._v(" password")]):_vm._e()])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }