<template>
  <div>
    <v-container fluid id="co">
      <v-card background-color="#191919" rounded="xl">
        <v-card-title>
          <span
            class="text-h5"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-5' : ''"
          >
            <v-btn
              icon
              @click="$emit('DontShowDetail', false)"
              v-if="$route.name !== 'Map' && role !== 'user'"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            Potential class

            <v-btn
              :ripple="false"
              width="144px"
              style="opacity: 0.9; cursor: unset"
              class="white--text ml-5"
              rounded
              :color="getColor(detailData.quality)"
              >{{ detailData.quality }}</v-btn
            >
          </span>
          <v-spacer></v-spacer>

          <div v-if="role === 'user'">
            <v-btn v-if="!$vuetify.breakpoint.mobile" text @click="exportCSV()">
              <v-icon>mdi-file-export-outline</v-icon>
              <span>Export</span>
            </v-btn>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="exportCSV()">
                  <v-icon>mdi-file-export-outline</v-icon>
                  <span v-if="!$vuetify.breakpoint.mobile">Export</span>
                </v-btn>
              </template>
              <span>Export</span>
            </v-tooltip>
          </div>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card flat>
                  <v-card-text>
                    <v-row justify="space-between">
                      <v-col>
                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon
                                >Global Horizontal Radiation (GHI):
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Global Horizontal Radiation (GHI): GHI is the
                                  total amount of radiation received on the
                                  horizontal surface of the earth.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_GHI * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon
                                >Elevation:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Elevation: The altitude of a location is
                                  inversely proportional to the thickness of the
                                  atmosphere, which implies that a denser air
                                  layer can lead to an increase in factors such
                                  as dust and air pollutants that can reflect
                                  radiation.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>
                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Dem * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon
                                >Access to Road:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Access to Road: The shorter the distance of
                                  the plant from the main roads, the lower the
                                  costs associated with the transportation of
                                  equipment, personnel, and maintenance
                                  services.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Road * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon
                                >Access to Power Grid:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Access to Power Grid: A significant distance
                                  between the power plant and power transmission
                                  lines can lead to several drawbacks such as
                                  voltage drop, reduction of overall efficiency,
                                  and high energy loss for the system.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Pline * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon
                                >Slope:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Slope: Typically, it is preferable to install
                                  solar panels on level ground with a slight
                                  slope ranging from zero to 10 degrees. The
                                  slope can impact the installation cost of the
                                  plant, and installed panels on steeper slopes
                                  may cast shadows on subsequent rows during
                                  certain times of the day, potentially
                                  affecting the overall performance of the
                                  plant.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>
                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Slope * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>
                      </v-col>
                      <v-col align="right">
                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon>
                                Ambient temperature:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Ambient temperature: Due to the
                                  characteristics of photovoltaic panels, the
                                  efficiency of solar panels can be reduced by
                                  increasing temperatures.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            rounded
                            color="#FFc700"
                            class="ml-5"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Temp * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon>
                                Suitability of Land Use:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Suitability of Land Use: Land use is one of
                                  the first steps in energy-related projects,
                                  which plays a significant role in estimating
                                  investment costs. Land use is presented in
                                  different categories, including salty land,
                                  barren, forest, rocky area, agricultural,
                                  waterbody, urban area, and pasturage.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            rounded
                            color="#FFc700"
                            class="ml-5"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Landuse * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon>
                                Aspect:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Aspect: The solar panels should be installed
                                  in areas that receive the highest solar
                                  irradiation throughout the year, regardless of
                                  the season. An azimuth of zero degrees is
                                  preferred over different directions.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Aspect * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon>
                                Risk of Earthquake:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Risk of Earthquake: Large earthquakes are
                                  often associated with Active fault zones,
                                  which can have an impact on the safety of a
                                  power plant.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            background-color="#D9D9D9"
                            height="11"
                            :value="
                              Math.ceil((detailData.GridCode_Fault * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>

                        <div class="d-flex justify-end align-center">
                          <v-tooltip right color="#191919">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small>mdi-information-outline</v-icon>
                                Risk of Flood:
                              </span>
                            </template>
                            <span>
                              <div style="width: 655px">
                                <span>
                                  Risk of Flood: Floods happen when there is an
                                  excessive amount of water in a specific area,
                                  and rivers and other bodies of water are often
                                  the source of this excess water. Therefore,
                                  during the feasibility study phase, it is
                                  crucial to consider this factor as a way to
                                  mitigate the risk of flooding for power
                                  plants.
                                </span>
                              </div>
                            </span>
                          </v-tooltip>

                          <v-progress-linear
                            style="width: 250px"
                            class="ml-5"
                            rounded
                            color="#FFc700"
                            height="11"
                            background-color="#D9D9D9"
                            :value="
                              Math.ceil((detailData.GridCode_River * 100) / 6)
                            "
                          ></v-progress-linear>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :align="$vuetify.breakpoint.mdAndUp ? 'left' : 'center'"
                :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
              >
                <!-- results -->
                <v-card flat>
                  <v-card-text>
                    <div class="mt-2">
                      <v-container>
                        <v-row
                          align="center"
                          justify="center"
                          class="d-flex flex-row flex-fill"
                        >
                          <div
                            class="flex-fill align-center justify-center mt-2"
                            :class="$vuetify.breakpoint.mobile ? '' : 'd-flex'"
                          >
                            <span> Annual Energy Production </span>

                            <v-btn
                              :ripple="false"
                              style="border-radius: 10px; cursor: unset"
                              class="black--text ml-3"
                              :class="$vuetify.breakpoint.mdAndUp ? '' : 'ma-2'"
                              color="#FFC700"
                            >
                              {{
                                Number(
                                  detailData.energyPower.slice(0, -3)
                                ).toLocaleString()
                              }}
                              kWh</v-btn
                            >
                          </div>

                          <div
                            class="flex-fill align-center justify-center"
                            :class="$vuetify.breakpoint.mobile ? '' : 'd-flex'"
                          >
                            <span> Capacity </span>
                            <br v-if="$vuetify.breakpoint.mdAndUp" />
                            <v-btn
                              :ripple="false"
                              style="border-radius: 10px; cursor: unset"
                              class="white--text ml-3 mt-2"
                              color="#0075ff"
                              :class="$vuetify.breakpoint.mdAndUp ? '' : 'ma-2'"
                            >
                              {{ detailData.capacity.toFixed(2) }}
                              {{ detailData.capacityUnit }}</v-btn
                            >
                          </div>
                        </v-row>
                      </v-container>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- data table bottom -->
                <div class="mt-5">
                  <v-simple-table dark dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Area</td>
                          <td>
                            {{ detailData.area + "  " }}
                            {{ detailData.areaUnit }}
                          </td>
                        </tr>

                        <tr>
                          <td>System Type</td>
                          <td>{{ detailData.panelType }}</td>
                        </tr>

                        <tr v-if="$vuetify.breakpoint.mdAndUp">
                          <td>Latitude/Longitude</td>
                          <td>
                            {{ detailData.lat.toFixed(5) }} /
                            {{ detailData.lon.toFixed(5) }}
                          </td>
                        </tr>

                        <tr v-if="$vuetify.breakpoint.mobile">
                          <td>Latitude</td>
                          <td>{{ detailData.lat.toFixed(5) }}</td>
                        </tr>

                        <tr v-if="$vuetify.breakpoint.mobile">
                          <td>Longitude</td>
                          <td>{{ detailData.lon.toFixed(5) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>

                <!-- buttons -->
                <v-row
                  class="mt-5"
                  v-if="role !== 'user' && $route.name === 'Map'"
                >
                  <v-col>
                    <v-btn
                      block
                      style="border-radius: 10px; font-weight: bold"
                      outlined
                      color="#FFC700"
                      @click="
                        $router.push({
                          name: 'Dashboard',
                          params: { role: role },
                        })
                      "
                    >
                      Go to dashboard
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-5"
                  v-if="role !== 'user' && $route.name !== 'Map'"
                >
                  <v-col cols="6">
                    <v-btn
                      block
                      style="border-radius: 10px; font-weight: bold"
                      right
                      outlined
                      :disabled="
                        detailData.phoneNumber !== 'phone number' ||
                        detailData.phoneNumber !== '' ||
                        detailData.phoneNumber !== null
                      "
                      color="#0075FF"
                      >call</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      block
                      style="border-radius: 10px; font-weight: bold"
                      right
                      outlined
                      @click="mailing(detailData.email)"
                      color="#FFC700"
                      >email</v-btn
                    >
                  </v-col>
                </v-row>
                <div class="mt-8 ml-5" v-if="role === 'user'">
                  <v-row justify="start">
                    <v-btn
                      style="border-radius: 9px"
                      right
                      outlined
                      color="green"
                      @click="mailing('help@ksi.solar')"
                      class="mr-2"
                      :small="$vuetify.breakpoint.mobile"
                      >contact</v-btn
                    >

                    <v-btn
                      v-if="$route.name === 'Map'"
                      outlined
                      style="border-radius: 10px"
                      color="#FFC700"
                      @click="
                        $router.push({
                          name: 'Dashboard',
                          params: { role: role },
                        })
                      "
                    >
                      Go to dashboard
                    </v-btn>

                    <plans-choose v-else />
                  </v-row>
                </div>
              </v-col>

              <!-- map -->
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
                <div>
                  <v-card>
                    <gmap-map
                      :options="{ mapOptions, disableDefaultUI: true }"
                      :zoom="11"
                      :center="center"
                      style="width: 100%; height: 18rem; border-radius: 15px"
                    >
                      <gmap-marker :position="center"></gmap-marker>
                    </gmap-map>
                  </v-card>
                </div>

                <v-container class="mt-3 text-left">
                  <span>
                    <span v-if="role === 'user'">Your</span>
                    <span v-else>User</span> intended location on the map
                  </span>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import PlansChoose from "./PlansChoose.vue";

export default {
  components: { PlansChoose },
  props: {
    detailData: Object,
  },
  data() {
    return {
      mapOptions: {
        styles: require("../../assets/css/map-style.json"),
      },
      center: {
        lat: this.detailData.lat,
        lng: this.detailData.lon,
      },
    };
  },
  computed: {
    role() {
      return this.$store.state.profile.roles;
    },
  },
  methods: {
    getColor(res) {
      if (res === "Very poor") return "#822E27";
      else if (res === "Poor") return "#8C510C";
      else if (res === "Excellent") return "#266036";
      else if (res === "Good") return "#0C706A";
      else if (res === "Very Good") return "#7A8C0C";
      else return "#305480";
    },

    mailing(x) {
      window.open(`mailto:${x}`);
    },
    getPic(res) {
      if (res === "Ground Mounted")
        return "/Ksi_Ground-Mounted-Solar-Power-Plant.jpg";
      else if (res === "Roof Top") return "/Ksi_rooftop_solar.jpg";
      else if (res === "Dua Axis Tracker") return "/Ksi-Dual_Axis_Traker.jpg";
      else return "/ksi-Sosy-S_380.jpg";
    },
    exportCSV() {
      const name = this.$store.state.profile.fullName;
      let v = this.detailData;

      var doc = new jsPDF();

      doc.text("Quality: " + v.quality, 10, 20);
      doc.text("System Type: " + v.panelType, 10, 30);
      doc.text("Annual Energy Production: " + v.energyPower, 10, 40);
      doc.text("Annual Energy Production unit: " + "kWh", 10, 50);
      doc.text("Capacity: " + v.capacity.toFixed(4), 10, 60);
      doc.text("Capacity Unit: " + v.capacityUnit, 10, 70);
      doc.text("Area: " + v.area, 10, 80);
      doc.text("Area Unit: " + v.areaUnit, 10, 90);
      doc.text("Latitude: " + v.lat, 10, 100);
      doc.text("Longitude: " + v.lon, 10, 110);
      doc.text("Global Horizontal Irradiance: " + v.GridCode_GHI, 10, 130);
      doc.text("Temperature: " + v.GridCode_Temp, 10, 140);
      doc.text("Aspect: " + v.GridCode_Aspect, 10, 150);
      doc.text("Elevation: " + v.GridCode_Dem , 10, 160);
      doc.text("Risk of Earthquake: " + v.GridCode_Fault, 10, 170);
      doc.text("Suitability of Land use: " + v.GridCode_Landuse, 10, 180);
      doc.text("Access to Power Grid: " + v.GridCode_Pline, 10, 190);
      doc.text("Risk of Flood: " + v.GridCode_River, 10, 200);
      doc.text("Access to Road: " + v.GridCode_Road, 10, 210);
      doc.text("Slope: " + v.GridCode_Slope, 10, 220);
      doc.text("Created at: " + v.createdAt, 10, 230);

      doc.save(`ksi-${name}.pdf`);
    },
  },
};
</script>

<style scoped>
#co {
  font-family: "Poppins", sans-serif;
}

tr:hover {
  background-color: transparent !important;
}
</style>
