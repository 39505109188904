import Vue from 'vue'
import './assets/css/main.css'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import * as VueGoogleMaps from "vue2-google-maps" // Import package
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAGn8-NN-0XbidKD3EFvIL2_kDwqzzzXv4",
    libraries: "places"
  }
});
// router.beforeEach((to , from, next)=>{

//   if(to.matched.some(r=>r.meta.requiresAuth)){
//     if(!localStorage.getItem("token")){
//       // next({
//       //   name:'/login',
//       //   params:{
//       //     text: 'login'
//       //   }
//       // })

//     }else{
//       next({
//         name: to
//       })
//     }
//   }else{
//     next()
//   }
// })


Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
