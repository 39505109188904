<template>
  <div>
    <v-img
      style="position: relative;"
      contain
      src="../assets/img/aboutUs.png"
    >
      <div class="middleText textJustify">
        <v-card
          height="100%"
          style="background-color: rgba(0, 0, 0, 0.5); color: white"
        >
          <v-card-title class="align-center justify-center">
            <span class="text-h4 mt-3 mb-3"> About Us</span>
          </v-card-title>
          <v-card-text
            class="align-center justify-center "
            style="font-size: large; word-break: keep-all; "
          >
           <div  class="align-center justify-center " style="line-height: 23.5px;">
            KSI Solar is a spin-off of Kirchner Solar Group GmbH, a German solar
            tracking pioneer and leading manufacturer of reliable, innovative
            and cost-effective solar tracking systems and photovoltaic energy
            solutions. KSG has installed more than 20,000 trackers and 7,500
            rooftop systems and solar parks in over 52 countries worldwide,
            receiving multiple awards for their commitment to quality,
            sustainability, and reliability. KSI Solar aims to continue KSG’s
            impressive work by further developing solar tracking technology for
            delivery to projects worldwide. The R&D department of KSI which
            works on software development is focused on creating cutting-edge
            tools for accurate assessment and prediction of solar energy
            potential. Leveraging their expertise in both software engineering
            and solar energy, this team has developed a powerful tool that can
            accurately assess the suitability of a location for a solar power
            plant and predict the annual energy generation of the system. The
            software is designed to provide valuable insights into the potential
            revenue and return on investment of a solar energy project, making
            it an ideal toolbox for investors looking to make informed decisions
            about investing in solar energy.
           </div>
          </v-card-text>
        </v-card>
      </div>
    </v-img>
  </div>
</template>
<script>
export default {
  name: "AboutPage",
  data() {
    return {
      text: "",
    };
  },
};
</script>
<style scoped>
.textJustify {
  text-align: center;
  color: #ffffff;
  opacity: 1 !important;
}

.middleText {
  position: absolute;
  left: 15.71%;
  right: 15.86%;
  top: 0;
  bottom: 0;
}
</style>
