import axios from "axios";
import store from "..";
const baseUrlSignUp = "http://65.108.91.247/api";

const state = {
  orders: [],
  resultOrder: {},
  panelTypeCounter: {},
  allOrderCounter: [],
};

const actions = {
  async fetchAllOrders({ commit }) {
    const me = localStorage.getItem("token");
    axios({
      method: "get",
      url: baseUrlSignUp + `/orders/{roles}`,
      headers: {
        Authorization: `Bearer ${me}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        commit("setAllorders", response.data.reverse());
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },

  async CreateAnOrder({ commit }, d) {
    const me = localStorage.getItem("token");

    axios({
      method: "post",
      url: baseUrlSignUp + `/orders/submit-order`,
      headers: {
        Authorization: `Bearer ${me}`,
        Accept: "application/json",
      },
      data: d,
    })
      .then((response) => {
        if (response.status === "error") return;

        commit("setResultOrder", response.data);
        if (response.statusText === "Created") {
          commit("showMessage", {
            content: "Your Order is Successfuly Created",
            color: "success",
          });
        }
        store.dispatch("fetchProfile");
      })
      .catch((e) => {
        console.log(e.message);
        if (e.message.includes('500')) {
          commit("showMessage", {
            content: "server is down! please try again another time ",
            color: "error",
          });
        } else {
          commit("showMessage", {
            content: e.response.data.message,
            color: "error",
          });
        }
      });
  },

  async getsPanelTypesValues({ commit }) {
    await axios({
      method: "get",
      url: baseUrlSignUp + `/report/panel`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        commit("setpanelTypeCounter", response.data);
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },

  async getAllOrderCounter({ commit }) {
    await axios({
      method: "get",
      url: baseUrlSignUp + `/report/inDayRequest`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        commit("setAllOrderCounter", response.data);
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },
};

const mutations = {
  setAllorders: (state, dt) => (state.orders = dt),
  setResultOrder: (state, t) => (state.resultOrder = t),
  setpanelTypeCounter: (state, dt) => (state.panelTypeCounter = dt),
  setAllOrderCounter: (state, dt) => (state.allOrderCounter = dt),
};

export default {
  state,
  actions,
  mutations,
};
