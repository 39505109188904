<template>
  <div>
  <dashboard v-if="$route.params.role === 'user'"/>
  <admin-dashboard v-else/>
</div>
</template>

<script>
import Dashboard from '@/components/dashboard/Dashboard.vue'
import AdminDashboard from '@/components/dashboard/AdminDashboard.vue'
export default {
  components: { Dashboard, AdminDashboard },

}
</script>

<style>

</style>