import axios from "axios";
const baseUrlSignUp = 'http://65.108.91.247/api'

const state = {
  usersInfo: [],
  adminsInfo: [],
};

const actions = {
  async fetchAllUsersInfo({ commit }) {
    const me = localStorage.getItem("token") || "";
    // users
    axios({
      method: "get",
      url: baseUrlSignUp + `/users/get-all-users?filterUser=user`,
      headers: {
        Authorization: `Bearer ${me}`,
      },
    })
      .then((response) => {
        const user = response.data;

        commit("setAllUsers", user);

        if (response.status === "error") return;
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });

    // admins
    axios({
      method: "get",
      url: baseUrlSignUp + `/users/get-all-users?filterUser=admin`,
      headers: {
        Authorization: `Bearer ${me}`,
      },
    })
      .then((response) => {
        const admin = response.data;

        commit("setAllAdmins", admin);

        if (response.status === "error") return;
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },

  async changeUserRole({ commit }, userId) {
    const me = localStorage.getItem("token") || "";

    axios({
      method: "patch",
      url:
        baseUrlSignUp +
        `/users/profile/add-admin/${userId.userId}?role=${userId.role}`,
      headers: {
        Authorization: `Bearer ${me}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.status === "error") return;
        commit("showMessage", {
          content: 'User Role Changed',
          color: "success",
        });
      })
      .catch((e) => {
        console.log(e.message);
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },
};

const mutations = {
  setAllUsers: (state, dt) => (state.usersInfo = dt),
  setAllAdmins: (state, dt) => (state.adminsInfo = dt),
};

export default {
  state,
  actions,
  mutations,
};
