import router from "@/router";
import axios from "axios";

const baseUrlSignUp = "http://65.108.91.247/api";


const state = {
  token: {},
};

const getters = {};

const actions = {
  async signUp({ commit }, dt) {
    axios({
      method: "post",
      url: baseUrlSignUp + `/auth/signUp`,
      headers: {},
      data: dt,
    })
      .then((response) => {
        console.log(response);
        if (response.status === "error") {
          commit("showMessage", {
            content: response.data.message,
            color: "error",
          });
        } else {
          localStorage.setItem("token", response.data.accessToken);
          commit("setToken", response.data.accessToken);
          commit('setProfile' , response.data.user);
          router.push('/')
          commit("showMessage", {
            content: 'Welcome ! Please Choose Your Solar Plan ... ',
            color: "success",
          })
        }
      })
      .catch((e) => {
        commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
      });
  },

  async login({ commit }, dt) {
    localStorage.removeItem("token");
    await axios({
      method: "post",
      url: baseUrlSignUp + `/auth/signIn`,
      headers: {},
      data: dt,
    })
      .then((response) => {
        if (response.error) {
          console.log(response)
          commit("showMessage", {
            content: response.message,
            color: "error",
          });
        }   
         localStorage.setItem("token", response.data.token)
          commit("setToken", response.data.token);
          commit('setProfile' , response.data.user)
          router.push('/')
          commit("showMessage", {
            content: 'Welcome ! Please Choose Your Solar Plan ... ',
            color: "success",
          });

      })
      .catch((e) => { 
        if(e.response !== undefined){
           commit("showMessage", {
          content: e.response.data.message,
          color: "error",
        });
        }else{
          console.log(e.response)
        }
       
       
      });
    
  },
};

const mutations = {
  setToken: (state, dt) => (state.token = dt),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
