<template>
  <v-main>
    <v-card dark flat :color="$vuetify.breakpoint.mobile ? 'transparent' : ''">
      <v-container v-if="!$vuetify.breakpoint.mdAndUp">
        <v-row class="align-center justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                @click="$router.push('/')"
                v-bind="attrs"
                v-on="on"
                src="../../assets/img/KSI-original.png"
                width=" 120 "
                max-width="120"
                class="mt-3"
              ></v-img>
            </template>
            <span>Home</span>
          </v-tooltip>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-else
        color="black"
        style="border-right: 1px solid #6a6262"
        v-model="drawer"
        app
        fixed
        width="8rem"
        class="justify-center align-center"
      >
        <template v-slot:prepend>
          <v-container fluid class="px-0">
            <v-row dense>
              <v-col>
                <v-list-item class="text-h4" link to="/">
                  <v-list-item-content
                    class="my_custom_orange justify-center align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          v-bind="attrs"
                          v-on="on"
                          src="../../assets/img/KSI-original.png"
                          width="80"
                          max-width="80"
                        ></v-img>
                      </template>
                      <span>Home</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template class="justify-center align-center">
          <div class="pa-2">
            <v-list style="margin-top: 10rem">
              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showAdminCharts ? false : true"
                        small
                        :color="showAdminCharts ? '#FFC700' : ''"
                        @click="
                          [
                            (showAdminCharts = true),
                            (showAdminProfile = false),
                            (showUsersTables = false),
                            (showAdminDataTable = false),
                          ]
                        "
                      >
                        <v-icon
                          :color="showAdminCharts ? 'black' : ''"
                          size="30"
                          >mdi-home-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Charts</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showAdminDataTable ? false : true"
                        small
                        :color="showAdminDataTable ? '#FFC700' : ''"
                        @click="
                          [
                            (showAdminCharts = false),
                            (showAdminProfile = false),
                            (showUsersTables = false),
                            (showAdminDataTable = true),
                          ]
                        "
                      >
                        <v-icon
                          :color="showAdminDataTable ? 'black' : ''"
                          size="30"
                          >mdi-table-account</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Requests</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-if="
                  $route.params.role === 'super_admin' ||
                  $route.params.role === 'Super-admin'
                "
              >
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showUsersTables ? false : true"
                        small
                        :color="showUsersTables ? '#FFC700' : ''"
                        @click="
                          [
                            (showAdminCharts = false),
                            (showAdminProfile = false),
                            (showAdminDataTable = false),
                            (showUsersTables = true),
                          ]
                        "
                      >
                        <v-icon
                          :color="showUsersTables ? 'black' : ''"
                          size="30"
                          >mdi-account-cog-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>User Management</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        :icon="showAdminProfile ? false : true"
                        small
                        :color="showAdminProfile ? '#FFC700' : ''"
                        @click="
                          [
                            (showAdminCharts = false),
                            (showAdminProfile = true),
                            (showUsersTables = false),
                            (showAdminDataTable = false),
                          ]
                        "
                      >
                        <v-icon
                          :color="showAdminProfile ? 'black' : ''"
                          size="30"
                          >mdi-account-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Profile</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </div>
        </template>

        <template v-slot:append>
          <div class="pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab icon @click="LogOut()">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>

    <!-- charts -->
    <v-card flat color="transparent" v-if="showAdminCharts">
      <v-container fluid>
        <v-card class="text--white" dark flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndUp"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <span class="text-h4 font-weight-bold">Dashboard</span>
          </v-card-title>
          <v-card-text>
            <plans-cards-with-num :titles="titles" />
            <admin-dashboard-charts />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>

    <!-- data table -->
    <v-card flat color="transparent" v-if="showAdminDataTable">
      <v-container fluid>
        <v-card class="text--white" dark flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndUp"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <span class="text-h4 font-weight-bold">Requests</span>
          </v-card-title>
          <v-card-text>
            <plans-cards-with-num
              v-on:selectedItem="getSelect($event)"
              :titles="titles"
              v-if="!showTopCardPlans"
            />

            <admin-data-table
              :selected="selected"
              v-on:showDetail="getShowTopCardPlans($event)"
              class="mt-5"
              v-on:All="getSelect($event)"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>

    <!-- users / admins  data-->

    <v-card flat color="transparent" v-if="showUsersTables">
      <v-container fluid>
        <v-card class="text--white" dark flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndUp"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <span class="text-h4 font-weight-bold">User Management</span>
          </v-card-title>
          <v-card-text>
            <admin-user-management />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>

    <!-- profile -->

    <v-card flat color="transparent" v-if="showAdminProfile">
      <v-container fluid>
        <v-card class="text--white" dark flat color="transparent">
          <v-card-title>
            <v-app-bar-nav-icon
              v-if="$vuetify.breakpoint.mdAndUp"
              @click="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <span class="text-h4 font-weight-bold">Profile</span>
          </v-card-title>
          <v-card-text>
            <profile />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>


    <v-bottom-navigation

      bottom
      fixed
      shift
      class="text-center"
      style="border-top: 1px solid #6a6262 ; background-color: black;"
      v-if="!$vuetify.breakpoint.mdAndUp"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-4"
            v-bind="attrs"
            v-on="on"
            fab
            :icon="showAdminCharts ? false : true"
            small
            :color="showAdminCharts ? '#FFC700' : ''"
            @click="
              [
                (showAdminCharts = true),
                (showAdminProfile = false),
                (showUsersTables = false),
                (showAdminDataTable = false),
              ]
            "
          >
            <v-icon :color="showAdminCharts ? 'black' : ''" size="30"
              >mdi-home-outline</v-icon
            >
          </v-btn>
        </template>
        <span>Charts</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            :icon="showAdminDataTable ? false : true"
            small
            :color="showAdminDataTable ? '#FFC700' : ''"
            @click="
              [
                (showAdminCharts = false),
                (showAdminProfile = false),
                (showUsersTables = false),
                (showAdminDataTable = true),
              ]
            "
          >
            <v-icon :color="showAdminDataTable ? 'black' : ''" size="30"
              >mdi-table-account</v-icon
            >
          </v-btn>
        </template>
        <span>Requests</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            v-if="
              $route.params.role === 'super_admin' ||
              $route.params.role === 'Super-admin'
            "
            v-bind="attrs"
            v-on="on"
            fab
            :icon="showUsersTables ? false : true"
            :color="showUsersTables ? '#FFC700' : ''"
            @click="
              [
                (showAdminCharts = false),
                (showAdminProfile = false),
                (showAdminDataTable = false),
                (showUsersTables = true),
              ]
            "
          >
            <v-icon :color="showUsersTables ? 'black' : ''" size="30"
              >mdi-account-cog-outline</v-icon
            >
          </v-btn>
        </template>
        <span>User Management</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            :icon="showAdminProfile ? false : true"
            small
            :color="showAdminProfile ? '#FFC700' : ''"
            @click="
              [
                (showAdminCharts = false),
                (showAdminProfile = true),
                (showUsersTables = false),
                (showAdminDataTable = false),
              ]
            "
          >
            <v-icon :color="showAdminProfile ? 'black' : ''" size="30"
              >mdi-account-outline</v-icon
            >
          </v-btn>
        </template>
        <span>Profile</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small v-bind="attrs" v-on="on" fab icon @click="LogOut()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-bottom-navigation>
  </v-main>
</template>

<script>
import PlansCardsWithNum from "../partial/PlansCardsWithNum.vue";
import AdminDashboardCharts from "./AdminDashboardCharts.vue";
import AdminDataTable from "./AdminDataTable.vue";
import AdminUserManagement from "./AdminUserManagement.vue";
import Profile from "./Profile.vue";
export default {
  components: {
    AdminDashboardCharts,
    Profile,
    PlansCardsWithNum,
    AdminDataTable,
    AdminUserManagement,
  },
  data() {
    return {
      selected: {},
      showTopCardPlans: false,
      showPlansCard: true,
      showAdminDataTable: false,
      showAdminProfile: false,
      showAdminCharts: true,
      showUsersTables: false,
      drawer: true,
    };
  },
  computed: {
    titles() {
      return [
        {
          id: 1,
          title: "Single Axis Tracker",
          color: "#FFC700",
          value: this.$store.state.orders.panelTypeCounter.singleAxisTracker,
        },
        {
          id: 2,
          title: "Dual Axis Tracker",
          color: "#0075FF",
          value: this.$store.state.orders.panelTypeCounter.dualAxisTracker,
        },
        {
          id: 3,
          title: "Ground Mounted",
          color: "green",
          value: this.$store.state.orders.panelTypeCounter.groundMounted,
        },
        {
          id: 4,
          title: "Roof Top",
          color: "red",
          value: this.$store.state.orders.panelTypeCounter.roofTop,
        },

        // {
        //   id: 5,
        //   title: "All",
        //   color: "white",
        //   value: this.$store.state.orders.panelTypeCounter.all,
        // },
      ];
    },
  },

  methods: {
    getSelect(x) {
      this.selected = x;
    },
    getShowTopCardPlans(x) {
      this.showTopCardPlans = x;
    },
    LogOut() {
      localStorage.clear();
      this.$router.push("/");
      window.location.reload();
    },
  },

  mounted() {
    this.$store.dispatch("getsPanelTypesValues");
  },
};
</script>

<style>
.active-button {
  border-radius: 50px; /* Replace 50px with the desired border radius */
  background-color: transparent;
}
</style>
